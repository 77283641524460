import React, { useState, useEffect, useRef, useCallback } from "react";
import { Row, Col, Card, Table } from "react-bootstrap";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import * as Yup from "yup";
// import { parse, isDate } from 'date-fns';
import { getValidationErrors } from "../../utils/getValidationErrors";

import { SimulationPlan, SimulationResult } from "./styles";

import Header from "../../components/Header";
import Layout from "../../components/Layout";
import Input from "../../components/Form/Input";
import Range from "../../components/Form/Range";
import Checkbox from "../../components/Form/Checkbox";
import { CardResult } from "../../components/SimulationResult/CardResult";
import ChartRentability from "../../components/SimulationResult/ChartRentability";

import { FormState, Porcentagem, ReqResult, Simulation } from "../../types/Simulation/simulation";

import configJson from '../../utils/config.json';
import Select from "../../components/Form/Select";

const schema = Yup.object().shape({
  dtNasc: Yup.date().required("Data de nascimento é obrigatória"),
  idadeInicRecebRenda: Yup.number()
    .typeError("Este campo é obrigatório")
    .required("Idade para receber renda é obrigatória")
    .min(55, "Idade mínima de 55 anos")
    .max(90, "Idade máxima de 90 anos"),
  valor: Yup.number()
    .typeError("Este campo é obrigatório (min. R$10,00)")
    .required("Este campo é obrigatório (min. R$10,00)")
    .min(10, "Valor mínimo de R$10,00"),
  valorAporte: Yup.number()
    .typeError("Este campo é obrigatório (min. 0)")
    .required("O valor do aporte inical é obrigatório")
    .min(0, "Valor mínimo é 0"),
});


var datestring = new Date().toISOString().split('T')[0];


const Dashboard: React.FC = () => {
  
  
  const [tipoIR, setTipoIR]               = useState({ checked: true });
  const [tipoSimulacao, setTipoSimulacao] = useState({ checked: true });
  const [ utils, setUtils ]               = useState({formaRecebimento:[], prazoInDeterminado:[], indice_UPViewModel:{valorUP:30}});
  // const [ formaRecebimento, setFormaRecebimento ]     = useState("N");
  // const [ prazoInDeterminado, setPrazoInDeterminado ] = useState(0.5);
  const [simulations, setSimulations]     = useState<Array<Simulation>>([]);
  const [formState, setFormState]         = useState<FormState>({
    dtNasc: datestring,
    idadeInicRecebRenda: 55,
    prazoRecebRenda: 10,
    taxaJuros: 4.09,
    tipoSimulacao: "C",
    formaRecebimento: "N",
    percPrazoIndeterminado: 0.5,
    tipoIR: "P",
    valor: 150.0,
    valorAporte: 0,
  });
  

  const handleChangeTipoSimulacao = (e: { target: { checked: boolean } }) => {
    setFormState({
      ...formState,
      tipoSimulacao: e.target.checked ? "C" : "R",
    });
    setTipoSimulacao({ checked: e.target.checked });
  };

  const handleChangeTipoIR = (e: { target: { checked: boolean } }) => {
    setFormState({
      ...formState,
      tipoIR: e.target.checked ? "P" : "R",
    });
    setTipoIR({ checked: e.target.checked });
  };

  const handleChange = (fieldName: keyof FormState) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLDataElement>
  ) => {
    setFormState({
      ...formState,
      [fieldName]: e.currentTarget.value,
    });
  };
  

  const [chart, setChart] = useState<Porcentagem>({
    porcentagemContribuicao: 0,
    porcentagemRendimento: 0,
  });


  const fetchAPI = useCallback(async () => {
    const response = await fetch(`${configJson.api_url}/api/v1/Simulador`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT",
      },
      body: JSON.stringify(formState),
    });
    // console.log(formState)
    const data: ReqResult = await response.json();
    setSimulations(data.resultado);
  }, [formState]);


  useEffect(() => {
    schema
      .validate(formState, {
        abortEarly: false,
      })
      .then(() => {
        formRef.current?.setErrors({});
        fetchAPI();
      })
      .catch((error) => {
        const errors = getValidationErrors(error);
        formRef.current?.setErrors(errors);
      });
  }, [formState, fetchAPI]);

  useEffect(() => {
    if (Array.isArray(simulations) && simulations.length > 0) {
      setChart({
        porcentagemContribuicao: simulations[0]?.porcentagemContribuicao,
        porcentagemRendimento: simulations[0]?.porcentagemRendimento,
      });
    }
  }, [simulations]);





  const getAdesao = async () => {
    const request = await fetch(`${configJson.api_url}/api/v1/Adesao`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT",
      }
    });
    request
    .json()
    .then(result => {
      // console.log(result)
      sessionStorage.setItem('@SimuladorSias.utils', JSON.stringify(result));
      setUtils(result);
    });
  }

  useEffect(() => {
    getAdesao()
  },[]);

  const formRef = useRef<FormHandles>(null);

  return (
    <>
      <Header />
      <Layout>
        <Col sm={12} md={6} lg={4} xl={3}>
          <SimulationPlan>
            <h1>Simulador de Adesão</h1>
            <Form ref={formRef} onSubmit={() => {}}>
              <label>
                Data de Nascimento
                <Input
                  name="dtNasc"
                  type="date"
                  defaultValue={formState.dtNasc}
                  onBlur={handleChange("dtNasc")}
                />
              </label>
              <label>
                Idade para receber minha renda
                <Input
                  name="idadeInicRecebRenda"
                  type="number"
                  min={55}
                  defaultValue={formState.idadeInicRecebRenda}
                  onChange={handleChange("idadeInicRecebRenda")}
                />
                <span className="d-block text-right small mt-2">
                Minimo 55 anos
                </span>
              </label>
              <label>
                <Select
                  name="formaRecebimento"
                  onChange={(e:any) => setFormState({ ...formState, formaRecebimento: e.target.value})}
                  defaultValue={formState.formaRecebimento}
                  label="Forma de Recebimento">
                  {utils?.formaRecebimento?.map((forma:any) => <option key={forma.codForma} value={forma.codForma}>{forma.descrForma}</option>)}
                </Select>
              </label>
              
              {formState.formaRecebimento === "P" && 
              <label>
                <Select
                  name="percPrazoIndeterminado"
                  onChange={(e:any) => setFormState({ ...formState, percPrazoIndeterminado: e.target.value})}
                  defaultValue={formState.percPrazoIndeterminado}
                  label="Percentual (% ao mês)">
                  {utils.prazoInDeterminado.map((prazo:any) => <option key={prazo} value={prazo}>{prazo}</option>)}
                </Select>
              </label>}

              {formState.formaRecebimento === "N" && 
              <label className="mb-4">
                Receber renda durante (anos)
                <Range
                  name="prazoRecebRenda"
                  type="range"
                  min={1}
                  max={20}
                  defaultValue={formState.prazoRecebRenda}
                  onChange={handleChange("prazoRecebRenda")}
                />
                <span className="d-block text-right mt-4 value">
                  {formState.prazoRecebRenda}
                  {formState.prazoRecebRenda <= 1 ? " ano" : " anos"}
                </span>
              </label>}


              <label className="w-100 d-flex flex-wrap align-items-center mb-3">
                <p className="w-100">
                  Simular pelo valor da renda mensal ou contribuição mensal?
                </p>
                <Checkbox
                  className="mb-0 mr-3"
                  name="tipoSimulacao"
                  type="checkbox"
                  checked={tipoSimulacao.checked}
                  onChange={handleChangeTipoSimulacao}
                />
                {tipoSimulacao.checked ? "Contribuição Mensal" : "Renda Mensal"}
              </label>
              <label className="w-100">
                Valor da{" "}
                {tipoSimulacao.checked ? "contribuição mensal" : "renda mensal"} R$
                <Input
                  name="valor"
                  type="number"
                  defaultValue={formState.valor}
                  min={utils.indice_UPViewModel.valorUP}
                  onChange={handleChange("valor")}
                />
                <span className="d-block text-right small mt-2">
                Minimo R$ {utils.indice_UPViewModel.valorUP}
                </span>
              </label>
              <label>
                Valor do aporte inicial (não obrigatório)
                <Input
                  name="valorAporte"
                  type="number"
                  defaultValue={formState.valorAporte}
                  onChange={handleChange("valorAporte")}
                />
              </label>
              <label className="w-100 d-flex flex-wrap align-items-center mb-3">
                <p className="w-100">Modalidade do IR</p>
                <Checkbox
                  className="mb-0 mr-3"
                  name="tipoIR"
                  type="checkbox"
                  checked={tipoIR.checked}
                  onChange={handleChangeTipoIR}
                />
                {tipoIR.checked ? "Progressivo" : "Regressivo"}
              </label>
              <label className="mb-3">
                Estimativa de rentabilidade do PrevSIAS
                <Range
                  name="taxaJuros"
                  type="range"
                  min={0.09}
                  max={10.09}
                  defaultValue={formState.taxaJuros}
                  onChange={handleChange("taxaJuros")}
                />
                <span className="d-block text-right small mt-4">
                  Taxa de Juros utilizada na simulação: {formState.taxaJuros}%
                  a.a
                </span>
              </label>
            </Form>
          </SimulationPlan>
        </Col>

        <Col>
          <SimulationResult>
            <h1>Resultado da simulação</h1>
            <Row>
              <Col className="d-flex flex-wrap flex-xl-nowrap">
                {simulations?.map((simulation, index) => (
                  <CardResult data={{...simulation, valorUP: utils.indice_UPViewModel.valorUP}} key={'simulation-'+index} />
                ))}
              </Col>
              <Col sm="12" className="mt-5">
                <Card>
                  <b>Valor do benefício mensal igual ou inferior a 8 UP, o saldo de conta será pago de forma única. Aumente o valor da contribuição mensal ou idade de aposentadoria ou então diminua o período do recebimento da renda.</b>
                  <br />
                  <b>Os cálculos e as hipóteses financeiras aqui apresentadas são apenas projeções e não há garantia de rentabilidade ou obrigação da SIAS. Os valores efetivos somente serão confirmados na data do evento e com base no saldo de conta acumulado pelo participante. Por fim, ressaltamos que os resultados apresentados no simulador estão deduzidos da taxa de administração e não consideram o imposto de renda que depende do regime de tributação escohido (Regressivo ou Progressivo).</b>
                </Card>
              </Col>
            </Row>

            <Row hidden>
              <Col sm="12" xl="4" className="mt-5">
                <Card className="mt-sm-4 mt-xl-0">
                  <Card.Body>
                    {/* <Card.Text> */}
                      <h3>Gráfico de rentabilidade</h3>
                    {/* </Card.Text> */}
                    <ChartRentability
                      porcentagemContribuicao={chart.porcentagemContribuicao}
                      porcentagemRendimento={chart.porcentagemRendimento}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col sm="12" xl="8" className="mt-5">
                <Card>
                  <Card.Body >
                    {/* <Card.Text> */}
                      <h3>Compare com outros investimentos</h3>
                    {/* </Card.Text> */}

                    <Table className="table-bordered text-center" responsive>
                      <thead>
                        <tr>
                          <th>Período</th>
                          <th className="sias-primary">PrevSIAS</th>
                          <th>Poupança</th>
                          <th>CDI</th>
                          <th>Tesouro</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1 ano</td>
                          <td className="sias-primary">R$ 10.000,00</td>
                          <td>A definir</td>
                          <td>A definir</td>
                          <td>A definir</td>
                        </tr>
                        <tr>
                          <td>5 anos</td>
                          <td className="sias-primary">R$ 30.000,00</td>
                          <td>A definir</td>
                          <td>A definir</td>
                          <td>A definir</td>
                        </tr>
                        <tr>
                          <td>10 anos</td>
                          <td className="sias-primary">R$ 100.000,00</td>
                          <td>A definir</td>
                          <td>A definir</td>
                          <td>A definir</td>
                        </tr>
                        <tr>
                          <td>30 anos</td>
                          <td className="sias-primary">R$ 581.232,00</td>
                          <td>A definir</td>
                          <td>A definir</td>
                          <td>A definir</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            {/* <Row>
              <Col>
                <Nav className="justify-content-end">
                  <Nav.Item>
                    <Nav.Link href="#">Imprimir simulação</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link href="#">Enviar por e-mail</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row> */}
          </SimulationResult>
        </Col>
      </Layout>
    </>
  );
};

export default Dashboard;
