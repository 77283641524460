import styled, { css } from 'styled-components';
import { transparentize } from 'polished';
import MenssageErro from '../MessageError';

interface ContainerProps {
  isFocused: boolean;
  isField: boolean;
  isErrored: boolean;
}

export const Container = styled.label<ContainerProps>`display: block;
  min-height: 5rem;
  width: 100%;
  font-size: 1.4rem;
  position: relative;
  margin-bottom: 1rem;

  span{
    text-transform: capitalize;
    margin-bottom: .5rem;
    display: block;
  }


  select {
    background: transparent;
    border: 0;
    width: 100%;
    padding: 1.4rem 2rem 1.4rem;
    
    min-height: 5rem;
    width: 100%;
    font-size: 1.4rem;
    border-radius: var(--border-radius);
    align-items: center;
    display: flex;
    background: var(--white);
    color: var(--color-text);
    transition: border 200ms, box-shadow 200ms;
    border: 1px solid ${transparentize(0.6, '#aab2bd')};
    position: relative;

    ${(props) =>
      props.isFocused &&
      css`
        & {
          border: 1px solid var(--primary);
          box-shadow: 0 0 0 0.3em rgba(255, 159, 0, 0.2);
        }
      `}
    ${(props) =>
      props.isField &&
      css`
        & {
          border: 1px solid var(--primary);
          select {
            color: var(--primary);
          }
        }
      `}
      
    ${(props) =>
      props.isErrored &&
      css`
        & {
          border: 1px solid var(--error);
          box-shadow: none;
          select {
            color: var(--error);
          }
        }
      `}
  }
`;
export const Error = styled(MenssageErro)`
  margin: 0 !important;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: flex;
    color: var(--error);
  }

  span {
    color: var(--error);
    position: absolute;
    width: 100%;
    top: calc(100% + 0.6rem);
    left: 0;
    z-index: 100000;
  }
`;
