import React, { useEffect, useState } from "react";
import { Row, Col, Container, Button, Card, ListGroup, ListGroupItem, Table } from "react-bootstrap";
import { Form } from '@unform/web';
import Input from '../../components/Form/Input/';
import Header from "../../components/Header";

import configJson from '../../utils/config.json';

const AdesaoFiles: React.FC = (props: any) => {
  // pega id da solicitação da url
  const [HASH_SOLIC] = useState(props.match.params.idSolic);
  const [IDSOLIC, setIdSolic] = useState();
  const [solicFiles, setSolicFiles] = useState([{}]);
  const [solic, setSolic] = useState({} as any);

  /**
   * Faz requisição GET
   * para pegar os documentos que foram anexados
   * a solicitação de adesão
   */
  const getFilesSolic = async (iD_SOLIC:any) => {
    // caso não possua IDSOLIC
    if (!iD_SOLIC) return;
    // requisição
    const response = await fetch(`${configJson.api_url}/api/v1/Documento/${HASH_SOLIC}`);
    const data: any = await response.json();
    // set files
    setSolicFiles(data.arquivos)
  }

  /**
   * FAz requisição GET
   * para pegar dados da solicitação
   */
  const getSolic = async () => {
    // caso não possua IDSOLIC
    if (!HASH_SOLIC) return;
    // requisição
    const response = await fetch(`${configJson.api_url}/api/v1/Adesao/${HASH_SOLIC}/solicitacao`);
    const data: any = await response.json();
    setIdSolic(data.dadosPessoais.iD_SOLIC);
    // set files
    data.dadosPessoais.dataSolicitacao = data.dadosSimulacao.dataSolicitacao;
    setSolic(data.dadosPessoais)
    getFilesSolic(data.dadosPessoais.iD_SOLIC);
  }


  useEffect(() => {
    getSolic();
  }, []);

  return (<>
    <Header />
    <Container>
      <Form onSubmit={console.log}>
        <Card>
          <Row>
            <Col sm={12}>
              <h1>Solicitação de adesão {solic.iD_SOLIC}</h1>
            </Col>
            <Col sm={12} md={3}>
              <Input
                name="cpf"
                label="CPF"
                disabled
                defaultValue={solic.cpf}
                mask="cpf"
              />
            </Col>
            <Col sm={12} md={6}>
              <Input
                name="nome"
                label="Nome"
                disabled
                defaultValue={solic.nome}
              />
            </Col>
            <Col sm={12} md={3}>
              <Input
                name="dataSolic"
                type="date"
                label="Data da Solicitação"
                disabled
                defaultValue={solic.dataSolicitacao?.split("T")[0]}
              />
            </Col>
          </Row>
        </Card>
      </Form>
      <Row>

        <Col sm={12}>
          <Card>
            <h1>Arquivos</h1>
            <Table>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Documento</th>
                  <th>Download</th>
                </tr>
              </thead>
              {solicFiles.map((file: any) =>
                <tr key={'documento-' + file.id_Doc}>
                  <td style={{ width: 100 }}>
                    {file.id_Doc}
                  </td>
                  <td style={{ width: 150 }}>
                    {file.descricao}
                  </td>
                  <td>
                    <a href={`${configJson.api_url}/api/v1/Documento/${IDSOLIC}/${file.id_Doc}`} target='_blank'>
                      Download
                    </a>
                  </td>
                </tr>)}
            </Table>

            {(solicFiles.length === 0) &&
            <Col sm={12}>
              <p>Nenhum documento encontrado para esta solicitação de Adesão.</p>
            </Col>}
          </Card>
        </Col>


        <Col sm={12}>
          <Card className="d-flex justify-content-center">
            <Row>
              <Col sm={12}><h1>Termo de adesão</h1></Col>
              <Col sm={12}>
                <a href={`${configJson.api_url}/api/v1/Adesao/${HASH_SOLIC}`} target='_blank'>
                  <Button type='button'>Download</Button>
                </a>
              </Col>
            </Row>


          </Card>
        </Col>

      </Row>
    </Container>
  </>)
}

export default AdesaoFiles;