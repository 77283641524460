// import { FiCalendar } from 'react-icons/fi';

import styled, { css } from 'styled-components';
import { transparentize } from 'polished';

import MenssageErro from '../MessageError';

interface ContainerProps {
  isFocused: boolean;
  isField: boolean;
  isErrored: boolean;
}

export const Container = styled.label<ContainerProps>`
  display: block;
  min-height: 5rem;
  width: 100%;
  font-size: 1.4rem;
  position: relative;
  margin-bottom: 1rem;
  
  span{
    margin-bottom: .5rem;
    display: block;
  }
  
  input {
    transition: border 200ms, box-shadow 200ms;
    border-radius: var(--border-radius);
    background: var(--white);
    color: var(--color-text);
    border: 1px solid ${transparentize(0.6, '#aab2bd')};
    width: 100%;
    padding: 1.4rem 2rem 1.4rem;
    flex: 1;
    background: var(--white);

    &[type='date'] {
      cursor: text;
    }
    &[type='date']::-webkit-calendar-picker-indicator {
      /* background: none; */
    }
    &[type='number'][type='number'] {
      appearance: textfield;
    }

    &[type='number']::-webkit-inner-spin-button {
      appearance: none;
    }

    ${(props) =>
    props.isFocused &&
    css`
      &:not([type='range']) {
        border: 1px solid var(--primary);
        box-shadow: 0 0 0 0.3em rgba(255, 159, 0, 0.2);
      }
    `}
  ${(props) =>
    props.isField &&
    css`
      &:not([type='checkbox']) {
        border: 1px solid var(--primary);
        color: var(--primary);
      }
      &[type='range'] {
        border: 0;
      }
    `}
  ${(props) =>
    props.isErrored &&
    css`
      &:not([type='checkbox']) {
        border: 1px solid var(--error);
        box-shadow: none;
        color: var(--error);
      }
    `}
  }

  &:not(:last-child) {
    margin-bottom: 1.6rem;
  }

  
`;

export const Error = styled(MenssageErro)`
  margin-top: 7px;
  margin-right: 7px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: flex;
    margin-top: -7px;
    margin-right: 7px;
    color: var(--error);
  }

  /* span {
    color: var(--error);
    position: absolute;
    width: 100%;
    top: calc(100% + 0.6rem);
    left: 0;
  } */
`;
