import { createGlobalStyle } from 'styled-components';
import { shade, transparentize } from 'polished';

export default createGlobalStyle`
  :root{
    --primary: #FF9F00;
    --primary-hover: ${shade(0.1, '#FF9F00')};
    --secondary: #004390;
    --secondary-hover: ${shade(0.4, '#004390')};

    --color-dark: #1F2227;
    --color-dark-hover: ${shade(0.4, '#1F2227')};
    
    --color-text: #434A54;
    --color-title: #053854;

    --color-border-title: ${transparentize(0.9, '#434A54')};

    --color-background: #F5F7FA;
    --color-background-light: #E8EAED;

    --color-graphic-primary: #F8D857;
    --color-graphic-secondary: #F8E8A9;

    --yellow: #FFC10A;
    --width: #FFF;
    --error: #FC2853;

    --border-radius: 6px;

    --font-primary: 'Bree Serif', serif;
    --font-secondary: 'Lato', sans-serif;
    --font-text: 'Lato', sans-serif;
    font-size: 62.5%; 
  }

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  body{
    background: var(--color-background);
    -webkit-font-smoothing: antialiased;
  }

  body, input, button{
    font: 1.4rem var(--font-secondary);
  }

  h1{
    font: 3rem var(--font-primary);
    color: var(--color-title);
    width: 100%;
    max-width: 78rem;
    margin: 0 auto 3.2rem auto;
    text-align: center;
    span {
      color: var(--primary);
    }
  }

  h3.title-form-group{
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.6rem;
    padding-bottom: 1rem;
    border-bottom: .1rem solid var(--color-border-title);
  }

  button, .btn{
    cursor: pointer !important;
    outline: 0 !important;
    border-radius: var(--border-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1.6rem 1.4rem;
    border: 0;
    outline: 0;
    font-size: 1.6rem;
    color: var(--white);
    font-weight: bold;
    transition: background-color 200ms ease-in-out, box-shadow 200ms ease-in-out;
    background-color: var(--color-dark);

    &:hover {
      color: var(--white);
      background-color: var(--color-dark-hover);
      box-shadow: 0 0 0 0.4rem rgba(67, 74, 84, 0.3);

      &:focus {
        border: 0;
        background-color: var(--color-dark-hover);
        box-shadow: 0 0 0 0.6rem rgba(67, 74, 84, 0.3);
      }
    }
  }

  .wrapper{
    width: 100%;
    max-width: 136rem;
    margin: 0 auto;
    margin-bottom: 4rem;
    }
    
  /* MODIFICAÇÒE NAS CLASSES DO BOOTSTRAP */
  .container{
    max-width: 160rem;
    padding: 2rem;
  }
  
  .card {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    border: none;
    border-radius: var(--border-radius);
    position: relative;
    padding: 2rem 2rem 2.5rem; 
    margin-bottom: 1.6rem;
    transition: transform 200ms ease-in-out;

    .card-header {
      margin-bottom: 2rem;
    }

    button,.btn {
      width: 90%;
      left: 50%;
      transform: translate(-50%, 15%);
      position: absolute; 
    }
  }

  .btn.btn-primary{
    background: var(--primary) !important;
    &:hover {
    background-color: var(--primary-hover);
    box-shadow: 0 0 0 0.4rem rgba(255, 159, 0, 0.3)!important;
    }

    &:focus {
      border: 0;
      background-color: var(--color-dark-hover);
      box-shadow: 0 0 0 0.6rem rgba(255, 159, 0, 0.3) !important;
    }
  }

  

  .wrapper-buttons{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 3.2rem;

    .btn,button {
      width: 100%;
      max-width: 24rem ;

      & + .btn{
        margin-left: 3rem;
      }
    }
  }
`;
