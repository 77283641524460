import styled from 'styled-components';
import { transparentize } from 'polished';

export const ComplementaryDataForm = styled.section`
  h1 {
    text-align: center;
  }

  form {
    .card {
      .subtitle-form-group {
        color: ${transparentize(0.6, '#434A54')};
        display: block;
        margin-bottom: 1.6rem;
      }
    }
    label {
      width: 100%;
      span {
        display: block;
        margin-bottom: 0.8rem;
      }
      font-size: 1.4rem;
      & > div {
        & > div {
          & > div:not(:last-child) {
            height: 5rem;
            border-radius: var(--border-radius);
            align-items: center;
            background: var(--white);
            color: var(--color-text);
            transition: border 200ms, box-shadow 200ms;
            cursor: pointer;
          }
        }
      }
    }
  }
`;

export const WrapperButtons = styled.div``;
