import React from "react";
import { Switch, Route } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import DadosComplementares from "../pages/DadosComplementares";
import Beneficiarios from "../pages/Beneficiarios";
import DadosBancarios from "../pages/DadosBancarios";
import Adesao from "../pages/Adesao";
import AdesaoFiles from "../pages/AdesaoFiles";

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={Dashboard} />
    <Route path="/dados-complementares" component={DadosComplementares} />
    <Route path="/beneficiarios" component={Beneficiarios} />
    <Route path="/dados-bancarios" component={DadosBancarios} />
    <Route path="/adesao/files/:idSolic" component={AdesaoFiles} />
    <Route path="/adesao" component={Adesao} />
  </Switch>
);

export default Routes;
