import styled from 'styled-components';

export const Container = styled.div`
  input[type='checkbox'] {
    position: relative;
    appearance: none;
    height: 2.4rem;
    width: 4rem;
    background: #ccc;
    outline: none;
    border-radius: 1.2rem;
    cursor: pointer;
    padding: 0;

    &:checked {
      background: var(--primary);
    }
    &:focus {
      border: 0;
      box-shadow: none;
    }

    &:before {
      content: '';
      position: absolute;
      height: 2.4rem;
      width: 2.4rem;
      border-radius: 1.2rem;
      top: 0;
      left: 0;
      background: var(--white);
      border: 2px solid #999;
      transition: left 200ms, border 200ms;
    }

    &:checked::before {
      top: 0;
      left: 1.65rem;
      border: 0.2rem solid var(--primary);
    }
  }
`;
