import React, { useState, useEffect } from 'react';
import { Row, Col, Card } from 'react-bootstrap';
import { Form } from '@unform/web';
import { Link, useHistory } from 'react-router-dom';
import Button from '../../components/Button';

import Layout from '../../components/Layout/';
import SimulatorNavigation from '../../components/SimulatorNavigation';
import Header from '../../components/Header';
import CheckboxRadio from '../../components/Form/CheckboxRadio';


import {  WrapperButtons } from './styles';
import { DadosBancariosSchema } from '../../types/DadosBancarios/dadosBancarios';
import { Dados } from '../../types/DadosComplementares/dadosComplementares';


const DadosBancarios: React.FC = () => {
  const history = useHistory();
  // Carrega Dados do localStorage para a tela
  const [formData, setFormData] = useState(JSON.parse(sessionStorage.getItem('@SimuladorSias.dadosBancarios') || '{}') as DadosBancariosSchema);
  const [simulacao]             = useState(JSON.parse(sessionStorage.getItem('@SimuladorSias.simulacao') || '{}'));
  const [dadosComplementares] = useState<Dados>(JSON.parse(sessionStorage.getItem('@SimuladorSias.dadosComplementares') || ''));
  const [permitirDescontoFolha] = useState(dadosComplementares.tipoInscrito === "1" || dadosComplementares.tipoInscrito == "4" || dadosComplementares.tipoInscrito == "6");
  /**
   *  Ações a serem executadas após o carregamento do componente
   */
  useEffect(() => {
    if(!permitirDescontoFolha)
    setFormData({ ...formData, ['tipoCobranca']: 'BOL' });
  }, []);


  /**
  * Salva dados no session storage
  */
  const saveToSessionStorage = () => {
    sessionStorage.setItem('@SimuladorSias.dadosBancarios', JSON.stringify(formData));
  }

  /**
   * Pega cada alteração realizada no form e itera ela ao objeto formData
   * utilizando o atributo 'name' e 'value' para posicionar no objeto final
   * @param e evento mudança de estado do formulario
   */
  const montFormObj = (e: any) => {
    let value = (e.target.type === "checkbox") ? e.target.checked : e.target.value;
    setFormData({ ...formData, [e.target.name]: value });
  }

  /**
   * Pega o objeto do formulario com os campos e valores passados na interface
   * @param formObj 
   */
  const handleSubmit = () => {
    /**
     * Altera visibilidade do input text
     */
    saveToSessionStorage();
    history.push('/beneficiarios');
  };


  return (
    <>
      <Header />
      <SimulatorNavigation />
      {/* <BankDataForm> */}
      <Form onSubmit={handleSubmit} onChange={(e) => montFormObj(e)}>
        <Layout>
          <Col sm={12}>
            <h1>
              Escolha a melhor forma para{' '}
              <span>honrar com seu investimento no PrevSIAS.</span>
            </h1>
          </Col>
          <Col>
            <Card>
              <Row>
                <Col sm={12}>
                  <h3 className="title-form-group">Cobrança</h3>
                </Col>
                <Col sm={12}>
                  <p className="">Valor da contribuição mensal:<b>{simulacao.valorContribuicao.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</b></p> 
                </Col>
                <Col>
                  <label>
                    <p className="">Tipo de cobrança</p>
                  </label>
                  <Row>
                    <Col md={6}>
                      <CheckboxRadio
                        name="tipoCobranca"
                        label="Boleto"
                        defaultChecked={formData.tipoCobranca === "BOL" || !permitirDescontoFolha}
                        value="BOL"
                      >
                      </CheckboxRadio>
                    </Col>
                    <Col md={6}>
                      <CheckboxRadio
                        name="tipoCobranca"
                        label="Desconto em folha, caso o empregador tenha convênio específico com a SIAS ( Necessário autorizar a consignação )."
                        value="DEB"
                        disabled={!permitirDescontoFolha}
                        defaultChecked={formData.tipoCobranca === "DEB"}
                      >
                      </CheckboxRadio>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm={12}>
            <WrapperButtons className="wrapper-buttons">
              <Link to="/dados-complementares" className="btn">
                Voltar
              </Link>
              <Button type='submit'
                className="btn btn-primary">
                Próximo
              </Button>
            </WrapperButtons>
          </Col>
        </Layout>
      </Form>
      {/* </BankDataForm> */}
    </>
  );
};

export default DadosBancarios;
