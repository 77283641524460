import { ValidationError } from 'yup';

export interface Errors {
  [key: string]: string;
}

export function getValidationErrors(err: ValidationError): Errors {
  const validationErrors: Errors = {};

  err.inner?.forEach((error) => {
    const key = error.path != null ? error.path : null;
    if (key != null) {
      validationErrors[key] = error.message;
    }
  });

  return validationErrors;
}
