import styled from 'styled-components';

export const Container = styled.div`
  input[type='range'] {
    -webkit-appearance: none;
    height: 4px;
    width: 100%;
    outline: none;
    border-radius: var(--border-radius);
    background: rgba(255, 159, 0, 0.3);
    cursor: pointer;
    padding: 0;

    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 1.6rem;
      width: 1.6rem;
      background: var(--white);
      border: 3px solid var(--primary);
      border-radius: 50%;
      cursor: pointer;
      outline: none;
      transition: box-shadow 200ms;
    }

    &::-webkit-slider-thumb:hover {
      box-shadow: 0 0 0 0.4rem rgba(255, 159, 0, 0.4);
    }
    &::-webkit-slider-thumb:active {
      box-shadow: 0 0 0 0.6rem rgba(255, 159, 0, 0.4);
    }
  }
`;
