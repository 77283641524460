import React, { useState, useEffect } from "react";
import { Row, Col, Card, Table, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
// import axios, { AxiosRequestConfig } from "axios";
// import { Form } from "@unform/web";

import Header from "../../components/Header/";
import SimulatorNavigation from "../../components/SimulatorNavigation";
import Layout from "../../components/Layout";
import ChartRentability from "../../components/SimulationResult/ChartRentability";
import Button from "../../components/Button";
// import Input from "../../components/Form/Input";

import AvatarUser from "../../assets/man.png";
import AvatarUserGirl from "../../assets/girl.png";

import { Simulation } from "../../types/Simulation/simulation";
import {
  IGlobal,
  // Parentesco,
  // IResponseParentesco,
  Dados,
  Indicacao,
  IAdesao,
  ResponsavelFinanceiro,
} from "../../types/DadosComplementares/dadosComplementares";
import {
  // Banco,
  Cobranca,
  ContaCorrente,
  // DadosBancariosSchema,
  // IBancoResponse,
  // IDadosBancarios,
} from "../../types/DadosBancarios/dadosBancarios";

// import { IToken } from "../../types/Adesao/adesao";

import {
  Title,
  UserData,
  TitleCard,
  Balance,
  Period,
  Result,
  WrapperTable,
  WrapperModal,
  WrapperButtons,
} from "./styles";

import { formatCPF } from "../../utils/formatCPF";
import { formatDate } from "../../utils/formatDate";
import { formatPhone } from "../../utils/formatPhone";


import configJson from '../../utils/config.json';
import { Beneficiario } from "../../types/Beneficiario/Beneficiario";
// import MessageError from "../../components/Form/MessageError";




const Adesao: React.FC = () => {


  // const [showToken, setShowToken]     = useState(false);
  // const [showWelcome, setShowWelcome] = useState(false);
  const [msgErro, setMsgErro]         = useState("");
  const [modalConcluido, setModalConcluido] = useState(false);
  const [ showLoading, setShowLoading ] = useState(false);

  // const [parentesco, setParentesco]       = useState<Parentesco>({} as Parentesco);
  // const [responseToken, setResponseToken] = useState<IToken>({} as IToken);
  // const [userToken, setUserToken]         = useState<IToken>({} as IToken);
  const [ listFiles, setListFiles ]       = useState([{docName:'CPF', docFile:''}, {docName:'RG', docFile:''}, {docName:'COMPROVANTE DE RESIDÊNCIA', docFile:''}] as any);

  const [dadosComplementares] = useState<Dados>(JSON.parse(sessionStorage.getItem('@SimuladorSias.dadosComplementares') || ''));
  const [dadosCobranca]       = useState<Cobranca>(JSON.parse(sessionStorage.getItem('@SimuladorSias.dadosBancarios') || ''));
  const [beneficiarios]       = useState<Beneficiario[]>(JSON.parse(sessionStorage.getItem('@SimuladorSias.beneficiarios') || ''));
  const [simulation]          = useState<Simulation>(JSON.parse(sessionStorage.getItem('@SimuladorSias.simulacao') || ''));


  useEffect(() => {
    if(!sessionStorage.getItem('@SimuladorSias.simulacao') || !sessionStorage.getItem('@SimuladorSias.utils'))
    window.location.href = '/';
  },[]);

  // const closeModalToken = () => setShowToken(false);

  // const closeModalWelcome = () => {
  //   setShowToken(false);
  //   sessionStorage.clear();
  // };


  /**
   * Padroniza objeto final de Beneficiario para o padrão esperado pela API
   * @param obj 
   * @returns 
   */
  const padronizaBeneficiario = (obj:any) => {
    obj.beneficiario_PrevSocial = obj.grupo === 1 ? "S" : "N";
    obj.beneficiario_Indicados  = obj.grupo === 1 ? "N" : "S";
    obj.proporcao               = parseInt(obj.proporcao);
    obj.cpf                     = obj.cpf.replaceAll(".","").replaceAll("-","");
    delete obj.grupo;
    delete obj.hash;
    return obj;
  }
  /**
   * MONTA OBJETO GLOOBAL DA ADESAO E ENVIA PARA O ENDPOINT
   */
  const handleSubmitAdesao = async () => {
    if(showLoading)
    return;


    let validaDocs = true;
    listFiles.map((file:any) => {
      if(!file.docFile){
        alert("Insira corretamente seu documento " + file.docName)
        validaDocs = false;
      }
    })

    if(!validaDocs)
    return;

    const objAdesao:IGlobal = {} as IGlobal;
    const { cpfIndicou, nomeIndicou, tipoInscrito, referencia, dataNascimento, cpf, nome, email, telefone, rg, sexo, telefoneCelular, rendaMensal, estadoCivil, nomeConjuge, profissao, endereco, bairro, cidade, estado, cep, pespolexp, pespolexP_PA } = dadosComplementares;
    const { tipoCobranca } = dadosCobranca;
    var TELEFONE_FINAL='', DDD_TELEFONE_FINAL='', CELULAR_FINAL='', DDD_CELULAR_FINAL='';
    setMsgErro("");
    /**
     * ADESAO
     */
    objAdesao.adesao              = {} as IAdesao;
    objAdesao.adesao.tipoInscrito = tipoInscrito;
    objAdesao.adesao.referencia   = tipoInscrito === "7" ? referencia : "";
    /**
     * INDICAÇÃO
     */
    objAdesao.indicacao           = {} as Indicacao;
    objAdesao.indicacao.nome      = nomeIndicou;
    if(cpfIndicou){
      objAdesao.indicacao.cpf       = cpfIndicou.replaceAll(".","").replaceAll("-","");
    }
    /**
     * DADOS
     */
    if(telefone){
      TELEFONE_FINAL = telefone.split(") ")[1];
      DDD_TELEFONE_FINAL = telefone.split(") ")[0].replace("(", "");
    }

    if(telefoneCelular){
      CELULAR_FINAL = telefoneCelular.split(") ")[1];
      DDD_CELULAR_FINAL = telefoneCelular.split(") ")[0].replace("(", "");
    }

    objAdesao.dados                 = {} as Dados;
    objAdesao.dados.cpf             = cpf;
    objAdesao.dados.nome            = nome;
    objAdesao.dados.dataNascimento  = dataNascimento;
    objAdesao.dados.email           = email;
    objAdesao.dados.ddd_telefone    = DDD_TELEFONE_FINAL;
    objAdesao.dados.telefone        = TELEFONE_FINAL;
    objAdesao.dados.rg              = rg;
    objAdesao.dados.sexo            = sexo;
    objAdesao.dados.ddd_celular     = DDD_CELULAR_FINAL;
    objAdesao.dados.telefoneCelular = CELULAR_FINAL;
    objAdesao.dados.rendaMensal     = rendaMensal;
    objAdesao.dados.estadoCivil     = estadoCivil;
    objAdesao.dados.nomeConjuge     = nomeConjuge;
    objAdesao.dados.profissao       = profissao;
    objAdesao.dados.endereco        = endereco;
    objAdesao.dados.bairro          = bairro;
    objAdesao.dados.cidade          = cidade;
    objAdesao.dados.estado          = estado;
    objAdesao.dados.cep             = cep;
    objAdesao.dados.pespolexp       = pespolexp    ? "S" : "N";
    objAdesao.dados.pespolexP_PA    = pespolexP_PA ? "S" : "N";
    /**
     * Responsável Financeiro
     */
    objAdesao.responsavelFinanceiro      = {} as ResponsavelFinanceiro;
    objAdesao.responsavelFinanceiro.cpf  = cpf;
    objAdesao.responsavelFinanceiro.nome = nome;
    /**
     *  COBRANÇA
     */
    objAdesao.cobranca                = {} as Cobranca;
    objAdesao.cobranca.tipoCobranca   = tipoCobranca;
    /**
     *  CONTA CORRENTE
     */
    objAdesao.contaCorrente           = {} as ContaCorrente;
    /**
     * SIMULACAO
     */
    objAdesao.simulacao               = simulation as Simulation;
    /**
     * PPE
     */
    objAdesao.politicamenteExposto = {}
    if(dadosComplementares.politicamenteExposta){
      objAdesao.politicamenteExposto = dadosComplementares.PPE;
      objAdesao.politicamenteExposto.dtnasc     = new Date(objAdesao.politicamenteExposto.dtnasc);
      objAdesao.politicamenteExposto.dtemiS_DOC = new Date(objAdesao.politicamenteExposto.dtemiS_DOC);
      objAdesao.politicamenteExposto.cpfppe     = objAdesao.politicamenteExposto.cpfppe.replaceAll(".","").replaceAll("-","")
    }

    /**
     * DEPENDENTES
     */
    objAdesao.dependentes             = beneficiarios
                                        .filter(x => x.nomeDependente)
                                        .map(padronizaBeneficiario) as Beneficiario[];

    setShowLoading(true);
    const request = await fetch(`${configJson.api_url}/api/v1/Adesao`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT",
      },
      body: JSON.stringify(objAdesao),
    });

    request
    .json()
    .then(res => {
      setShowLoading(!res.message);
      setMsgErro(res.message);
      if(!res.message){
        enviaArquivos(res.id_Solic)
        setModalConcluido(!res.message);
        setTimeout(() => {
          sessionStorage.clear();
          window.location.href = "/";
        }, 5000);
      }
    });
  }




  const addFile = () => {
    listFiles.push({docName:'', docFile:''} as any);
    setListFiles(listFiles);
  }

  const removeFile = (indice:number) => {
    listFiles.splice(indice, 1);
    setListFiles(listFiles);
  }

  const editFile = async (e:any, index:number) => {
    if(e.target.name === 'docFile')
    listFiles[index][e.target.name] = await toBase64(e.target.files[0]); //e.target.files[0];
    else
    listFiles[index][e.target.name] = e.target.value;
    setListFiles(listFiles);
  }

  const toBase64 = (file:File) => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
  });

  const enviaArquivos = async(id_solic:any) => {
    listFiles.forEach(async (file:any) => {

      let documento = {
        Descricao:    file.docName,
        ArquivoBase64: file.docFile,
      }
      // console.log()

      const options:RequestInit = {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST, GET, OPTIONS, DELETE, PUT",
        },
        body: JSON.stringify(documento)
      };
      
      
      fetch(configJson.api_url + '/api/v1/Documento?id_solic='+id_solic, options)
        .then(response => response.json())
        .then(response => console.log(response))
        .catch(err => console.error(err));

    });
  }


  return (
    <>
      <Header />
      <SimulatorNavigation />
      <Layout>
        <Col>
          <Title>
            <span>Confirme seus dados </span> inseridos anteriormente para fazer
            sua adesão.
          </Title>
        </Col>
        <Col sm="12">
          <UserData>
            <div>
              <img src={dadosComplementares.sexo == "F" ? AvatarUserGirl : AvatarUser} alt="Usuário" />
            </div>
            <p>
              <span>
                Olá, <strong>{dadosComplementares.nome}</strong>
              </span>
              <span>
                Confira se seus dados estão corretos, e confirme sua inscrição
                no PrevSIAS...
              </span>
            </p>
          </UserData>
        </Col>
        <Col sm="12">
          <Row>
            <Col sm="12" lg="4" className="d-flex">
              <Card className="suggestion align-self-stretch w-100 mt-0">
                <Card.Body>
                  <Card.Title>
                    <TitleCard>Sua renda mensal (provável) será</TitleCard>
                    <Balance>
                      {simulation.valorBeneficio.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
                    </Balance>
                    <Period>
                      Durante {simulation.prazoRecebRendaMeses} meses
                    </Period>
                  </Card.Title>
                  <Card.Text>
                    <Result>
                      Investindo{" "}
                      <strong> {simulation.valorContribuicao.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong> /mês
                      por
                      <strong>{simulation.descricaoTempoContribuicao}</strong>,
                      com aporte inicial de {simulation.valorAporteInicial.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}.
                      Vai acumular <strong>{simulation.saldoBruto.toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}</strong>
                    </Result>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" lg="4" className="d-flex">
              <Card className="more-details align-self-stretch mt-0">
                <Card.Body>
                  {/* <Card.Text> */}
                  <TitleCard>Mais detalhes</TitleCard>
                  <Result>
                    O primeiro recebimento será no dia
                    <strong>
                      {" "}
                      {formatDate(simulation.dataPrimeiroRecebimento)}
                    </strong>
                    {/* , descontando o imposto Regressivo no valor de{" "}
                    <strong>
                      {" "}
                      R$ {simulation.ir.valor.toLocaleString()}
                    </strong>{" "}
                    aplicado a uma alíquota de{" "}
                    <strong>
                      {" "}
                      {simulation.ir.aliquota.toLocaleString()}% de IR
                    </strong> */}
                    .
                  </Result>
                  {/* </Card.Text> */}
                </Card.Body>
              </Card>
            </Col>
            <Col md="6" lg="4" hidden>
              <Card className="Graphic">
                <Card.Body>
                  {/* <Card.Text> */}
                  <TitleCard>Gráfico de rentabilidade</TitleCard>
                  {/* </Card.Text> */}
                  <ChartRentability
                    porcentagemContribuicao={simulation.porcentagemContribuicao}
                    porcentagemRendimento={simulation.porcentagemRendimento}
                  />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col sm="12">
          <WrapperTable>
            <Card>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>
                      <h3>Meus Dados</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nome</td>
                    <td>{dadosComplementares.nome}</td>
                  </tr>
                  <tr>
                    <td>CPF</td>
                    <td>{formatCPF(dadosComplementares.cpf)}</td>
                  </tr>
                  <tr>
                    <td>Data de Nascimento</td>
                    <td>
                      {formatDate(dadosComplementares.dataNascimento || "")}
                    </td>
                  </tr>
                  <tr>
                    <td>E-mail</td>
                    <td>{dadosComplementares.email}</td>
                  </tr>
                  <tr>
                    <td>Telefone</td>
                    <td>{formatPhone(dadosComplementares.telefoneCelular)}</td>
                  </tr>
                </tbody>
              </Table>
              <Link to="/dados-complementares" className="btn">
                Editar
              </Link>
            </Card>
          </WrapperTable>
        </Col>
        <Col sm="12">
          <WrapperTable>
            <Card>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>
                      <h3>Dados do familiar que indicou</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nome</td>
                    <td>{dadosComplementares.nomeIndicou || "-"}</td>
                  </tr>
                  <tr>
                    <td>CPF</td>
                    <td>{dadosComplementares.cpfIndicou ? formatCPF(dadosComplementares.cpfIndicou) : "-"}</td>
                  </tr>
                </tbody>
              </Table>
              <Link to="/dados-complementares" className="btn">
                Editar
              </Link>
            </Card>
          </WrapperTable>
        </Col>
        {/* <Col sm="12">
          <WrapperTable>
            <Card>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>
                      <h3>Detalhes responsável financeiro</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Nome</td>
                    <td>{dadosComplementares.nome}</td>
                  </tr>
                  <tr>
                    <td>CPF</td>
                    <td>{formatCPF(dadosComplementares.cpf)}</td>
                  </tr>
                </tbody>
              </Table>
              <Link to="/dados-complementares" className="btn">
                Editar
              </Link>
            </Card>
          </WrapperTable>
        </Col> */}


      <Col sm="12">
        <WrapperTable>
          <Card>
            <Table bordered hover>
              <thead>
                <tr>
                  <th>
                    <h3>Documentos anexados</h3>
                  </th>
                </tr>
              </thead>
              <tbody>

                {listFiles.map((file:any, index:number) =>
                <tr key={'file-'+index}>
                  <td>
                    <div>
                      <b>{file.docName}</b>
                    </div>
                  </td>
                  <td>
                    <div>
                      <input className="form-control" required type="file" onInput={(e:any) => editFile(e, index)}  name="docFile" id="docFile" />
                    </div>
                  </td>
                </tr>)}


              </tbody>
            </Table>
            {/* <Link to="#!" className="btn" onClick={addFile}>
              Adicionar documento
            </Link> */}
          </Card>
        </WrapperTable>
      </Col>





        <Col sm="12">
          <WrapperTable>
            <Card>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <h3>Tipo de cobrança</h3>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        {dadosCobranca.tipoCobranca === "BOL" && "Boleto Bancário"}
                        {dadosCobranca.tipoCobranca === "DEB" && "Desconto em folha"}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              <Link to="/dados-bancarios" className="btn">
                Editar
              </Link>
            </Card>
          </WrapperTable>
          {msgErro !== "" &&
          <Col sm={12} className="text-center text-danger pt-4">
            <h2>
              <b>{msgErro}</b>
            </h2>
          </Col>}


          <Button
          onClick={handleSubmitAdesao}
          className="btn btn-primary w-100" >
            {showLoading ?
            <>            
            <div className="spinner-border mr-2" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            Validando adesão...
            </>
            :
            'Fazer Adesão'}
            
          </Button>
        </Col>



        <WrapperModal>
          <Modal show={modalConcluido} onHide={setModalConcluido} centered size="lg">
            <Modal.Header closeButton>
              <Modal.Title>
                <Title>
                  Solicitação de adesão enviada com sucesso!
                </Title>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Redirecionando à pagina inicial...
            </Modal.Body>
            <Modal.Footer>
              <WrapperButtons>
                <Button
                  className="btn btn-success"
                >Sair</Button>
              </WrapperButtons>
            </Modal.Footer>
          </Modal>
        </WrapperModal>





      </Layout>
    </>
  );
};

export default Adesao;
