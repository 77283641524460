import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  margin: 3.2rem auto;
  display: flex;
  justify-content: center;

  a.disable {
    pointer-events: none !important;
    cursor: default !important;
  }

  nav[aria-label='breadcrumb'] {
    width: 100%;
    max-width: 60rem;
  }

  .breadcrumb {
    width: 100%;
    max-width: 60rem;
    background: none;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    margin: 0;

    li {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: relative;
      a {
        display: flex;
        flex-direction: column;
        color: var(--primary);
        text-decoration: none;
        width: 100%;
        max-width: 20rem;
      }

      &:not(:first-child)::before {
        content: '';
        position: absolute;
        height: 0.2rem;
        width: 100%;
        max-width: 30vw;
        top: 36%;
        transform: translateX(-50%);
        background: #aab2bd;
        z-index: -1;
      }
    }
    span {
      flex: 1 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 6rem;
      width: 6rem;
      border: 0.2rem solid var(--primary);
      border-radius: 50%;
      background: var(--white);
      position: relative;
      font-weight: bold;
      left: 50%;
      transform: translateX(-50%);
      margin-bottom: 0.6rem;
      transition: background-color 200ms ease-in-out, color 200ms ease-in-out;

      &:hover {
        background-color: var(--primary);
        color: var(--white);
      }
    }
  }
`;
export const OrdinalNumbers = styled.span``;

export const TitleCard = styled.span``;
