import React from 'react';

import { Container, Row } from 'react-bootstrap';

export const Layout: React.FC = (props) => {
  return (
    <Container>
      <Row>{props.children}</Row>
    </Container>
  );
};

export default Layout;
