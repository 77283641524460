import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FaExclamationCircle } from 'react-icons/fa';

import { useField } from '@unform/core';

import { Container, Error } from './styles';

import { cpf, phoneNumber } from './masks';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  mask?: string;
  label?: string;
  error?: string;
}

const Input: React.FC<InputProps> = ({ icon: Icon, name, mask, error, label, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isField, setIsField] = useState(false);
  const { fieldName, registerField, defaultValue = [] } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsField(!!inputRef.current?.value);
  }, []);

  const handleInput = useCallback(
    (e: React.FormEvent<HTMLInputElement>) => {
      if (mask === 'cpf') {
        cpf(e);
      }
      if (mask === 'phoneNumber') {
        phoneNumber(e);
      }
    },
    [mask]
  );

  return (
    <Container isErrored={!!error} isField={isField} isFocused={isFocused}>
      <span>{label}</span>
      <input
        name={name}
        defaultValue={defaultValue}
        ref={inputRef}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onInput={handleInput}
        {...rest}
        // required
      />
      {error && (
        <Error title={error}>
          <FaExclamationCircle size={16} />
        </Error>
      )}
    </Container>
  );
};

export default Input;
