import styled from 'styled-components';

export const Wrapper = styled.header`
  background-color: var(--white);
  padding: 1.5rem 0;
  display: flex;
  justify-content: center;
  margin-bottom: 6rem;
  border-top: 1rem solid var(--secondary);
`;
