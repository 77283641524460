import React, { useState, useEffect, ChangeEventHandler } from 'react';
import { Col, Card, Row, Modal, Alert } from 'react-bootstrap';

import { Form } from '@unform/web';
import { Link, useHistory } from 'react-router-dom';
import { Dados } from '../../types/DadosComplementares/dadosComplementares';
import configJson from '../../utils/config.json';

// componentes 
import Layout from '../../components/Layout';
import SimulatorNavigation from '../../components/SimulatorNavigation';
import Header from '../../components/Header';
import Select from '../../components/Form/Select/';
import Input from '../../components/Form/Input/';
import Checkbox from '../../components/Form/Checkbox';
import CheckboxRadio from '../../components/Form/CheckboxRadio';
import Button from '../../components/Button';
import { ComplementaryDataForm } from './styles'; // style
import { Title, WrapperModal, WrapperButtons } from "../Adesao/styles";
import { Simulation } from '../../types/Simulation/simulation';
import ValidaCPF from '../../utils/validCPF';

interface iInscrito {
  codigo: string;
  tipo: string;
}

const DadosComplementares: React.FC = () => {
  const history = useHistory();
  // const [participante, setParticipante] = useState<Participante>({} as Participante);
  // Carrega Dados do localStorage para a tela
  const [formData, setFormData] = useState(JSON.parse(sessionStorage.getItem('@SimuladorSias.dadosComplementares') || '{"politicamenteExposta":false,"possuiRelacionamento":false, "PPE":{}}') as Dados);
  const [utils] = useState(JSON.parse(sessionStorage.getItem('@SimuladorSias.utils') || '{estadoCivil:[]}'));
  const [simulation]          = useState<Simulation>(JSON.parse(sessionStorage.getItem('@SimuladorSias.simulacao') || '{}'));
  const [msgErroIndicou, setMsgErroIndicou] = useState(false);
  const [modalPPE, setModalPPE] = useState(false);
  const [inscritosArr, setInscritosArr] = useState<iInscrito[]>([]);
  const [ formDataError, setFormDataError ] = useState<any>({PPE:{}});
  /**
   *  Ações a serem executadas após o carregamento do componente
   */
  useEffect(() => {
    
    if(utils.inscritos){
      let objUtilsInscritos = [
        utils.inscritos[3],
        utils.inscritos[4],
        utils.inscritos[5],
        utils.inscritos[0],
        utils.inscritos[1],
        utils.inscritos[2],
        utils.inscritos[6],
      ];
      console.log(objUtilsInscritos)
      setInscritosArr(objUtilsInscritos);
    }
  //   setFormData({...formData, dataNascimento: simulation.dataNascimento});
  //   console.log(utils?.cidade
  //     ?.filter((cidade: any) => cidade.idestado == utils.estado.filter((estado: any) => estado.sigla == formData.estado)[0]?.idestado))
  }, [utils]);


  /**
  * Salva dados no session storage
  */
  const saveToSessionStorage = () => {
    sessionStorage.setItem('@SimuladorSias.dadosComplementares', JSON.stringify(formData));
  }

  /**
   * Pega cada alteração realizada no form e itera ela ao objeto formData
   * utilizando o atributo 'name' e 'value' para posicionar no objeto final
   * @param e evento mudança de estado do formulario
   */
  const montFormObj = (e: any) => {
    let value = (e.target.type === "checkbox") ? e.target.checked : e.target.value;
    // if(value !== undefined){
      if(e.target.name && e.target.name.includes('PPE.')){
        formData.PPE[e.target.name.split('.')[1]] = value;
        setFormData(formData);
      }else{
        // console.log(value)
        if(e.target.name == 'cep'){
          let fd:Dados = formData;
          fd.cep = value;
          setFormData(fd);

        }else if(e.target.name == 'cpf'){
          let fd:Dados = formData;
          fd.cpf = value;
          setFormData(fd);

        }else if(e.target.name == 'cpfIndicou'){
          let fd:Dados = formData;
          fd.cpfIndicou = value;
          setFormData(fd);

        }else{
          setFormData({ ...formData, [e.target.name]: value });
        }
      }
    // }
    // console.log(formData)
    if(e.target.name === 'cpf' || e.target.name === 'cpfIndicou'){
      handleParticipante(e,e.target.name === 'cpf' ? 'dados' : 'indicou')
    }else if(e.target.name == 'cep'){
      handleEndereco(e);
    }

    
    if(e.target.name === 'possuiRelacionamento' && value){
      setModalPPE(true);
    }

  
    setFormDataError({...formDataError, [e.target.name]: null });
    saveToSessionStorage();
  }

  /**
   * Pega o objeto do formulario com os campos e valores passados na interface
   * @param formObj 
   */
  const handleSubmit = (e:any) => {

    Object.keys(e).forEach((el:any) => {
      if(e[el]){
        if(el && el.includes('PPE.')){
          formData.PPE[el.split('.')[1]] = e[el];
          setFormData(formData);
        }else{
          setFormData({ ...formData, [el]: e[el] });
        }
      }
    });

    /**
     * Altera visibilidade do input text
     */
    if (formData.tipoInscrito !== "7") {
      setFormData({ ...formData, referencia: "" });
    }
    setTimeout(() => {

    // console.log(formData)
    saveToSessionStorage();
    history.push('/dados-bancarios');
    
    },100);
  };

  /**
   * 
   * Pega dados do endereço passado de acordo com o CEP informado
   * 
   */
  const handleEndereco = async (e: any) => {
    let cep = e.target.value.replaceAll('-', '');
    if (!cep || cep.length < 8) return;

    await fetch(`${configJson.api_url}/api/Cep?cep=${cep}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
      },
    })
      .then(response => response.json())
      .then(endereco => {
        formData.estado = endereco.uf.toUpperCase();
        formData.cidade = endereco.cidade.toUpperCase();
        formData.bairro = endereco.bairro;
        formData.endereco = endereco.descricao;
        setFormData(formData);
      })
      .catch(err => console.error(err));
      
    montFormObj({ target: {} });
  }


  /**
   * Pega dados do participante na API e traz os dados para a tela
   */
  const handleParticipante = async (e: any, target: string = 'dados') => {
    let cpf = e.target.value.replaceAll('.', '').replaceAll('-', '');

    // não chama api caso não o cpf não tenha 11 digitos
    if (!cpf || cpf.length !== 11) return;

    // valida CPF
    if(!ValidaCPF(cpf)){
      alert("CPF INVALIDO");
      if(target === 'indicou')
        setFormData({...formData, cpfIndicou: ''});
      else
        setFormData({...formData, cpf: ''});
      return;
    }

    // get CPF
    const request = await fetch(`${configJson.api_url}/api/v1/Participante/${cpf}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
      },
    });
    const data: any = await request.json();
    const { participante } = data;
    if (participante) {
      console.log(participante)
      if (target === 'indicou') {
        setMsgErroIndicou(false);
        // formData.cpfIndicou = participante.cpf;
        formData.nomeIndicou = participante.nome;
      } else {
        formData.bairro = participante.bairro;
        formData.cep = participante.cep;
        formData.cidade = participante.cidade.toUpperCase();
        formData.estadoCivil = participante.codestciv;
        // formData.cpf = participante.cpf;
        // formData.dataNascimento = participante.dataNascimento;
        // formData.descR_ESTCIV = participante.descR_ESTCIV;
        formData.email = participante.emailpes;
        formData.endereco = participante.endereco;
        formData.estado = participante.estado.toUpperCase();
        formData.nomeConjuge = participante.nomE_CONJUGE;
        formData.nome = participante.nome;
        formData.rg = participante.rg;
        formData.sexo = participante.sexo;
        formData.teM_PREVSIAS = participante.teM_PREVSIAS;
        formData.inscricaoInstituidor = participante.teM_AFUSI;

        if (participante.ddd_celular && participante.telefonE_CEL)
          formData.telefoneCelular = '(' + participante.ddd_celular + ') ' + participante.telefonE_CEL;

        if (participante.ddD_RES && participante.telefonE_RES)
          formData.telefone = '(' + participante.ddD_RES + ') ' + participante.telefonE_RES;
      }
    } else {
      if (target === 'indicou') {
        setMsgErroIndicou(true);
        formData.nomeIndicou = "";
        formData.cpfIndicou = "";
      }
    }
    setFormData(formData);
    montFormObj({ target: {} });
  }


  const handleInvalid = (e:any) => {
    let msg;
    if(e.target.type == 'radio')
    msg = 'Selecione uma destas opções!'
    else
    msg = 'Preencha corretamente este campo!';

    setFormDataError({...formDataError, [e.target.name]: msg });
  }

  return (
    <>
      <Header />
      <SimulatorNavigation />
      <ComplementaryDataForm>
        <Layout>
          <Col sm={12}>
            <h1>
              Preencha seus dados. <span>E faça parte do PrevSIAS.</span>
            </h1>
          </Col>
          {formData.teM_PREVSIAS === "S" &&
          <Col sm={12} className="text-center text-danger pt-4">
            <h2>
              <b>CPF já cadastrado no Plano PrevSIAS. Adesão não permitida!</b>
            </h2>
          </Col>}
          <Col sm={12}>
            <Form onSubmit={handleSubmit} onInvalid={handleInvalid} onInput={(e) => montFormObj(e)} autoComplete="off" >
              {/* Dados Pessoais */}
              <Card>
                <Row>
                  <Col sm={12}>
                    <h3 className="title-form-group">Dados Pessoais</h3>
                  </Col>
                  <Col sm={12} md={6} lg={3}>
                    <Input
                      required
                      name="cpf"
                      error={formDataError.cpf}
                      defaultValue={formData.cpf}
                      label="CPF"
                      mask="cpf"
                    />
                  </Col>
                  <Col sm={6}>
                    <Input
                      required
                      name="nome"
                      label="Nome"
                      error={formDataError.nome}
                      value={formData.nome}
                    />
                  </Col>
                  <Col sm={12} md={6} lg={3}>
                    <Input
                      name="rg"
                      error={formDataError.rg}
                      value={formData.rg}
                      label="RG"
                      required={false}
                    // mask="rg"
                    />
                  </Col>
                  <Col md={6} lg={3}>
                    <Input
                      required
                      name="dataNascimento"
                      error={formDataError.dataNascimento && new Date(formData.dataNascimento).toISOString().split('T')[0]}
                      value={formData.dataNascimento && new Date(formData.dataNascimento).toISOString().split('T')[0]}
                      type="date"
                      disabled
                      label="Data de Nascimento"
                    />
                  </Col>
                  <Col lg={3}>
                    <Select
                      required
                      name="sexo"
                      error={formDataError.sexo}
                      value={formData.sexo}
                      label="Gênero"
                    >
                      {!formData.sexo && <option value="0">Selecione</option>}
                      <option value="M">Masculino</option>
                      <option value="F">Feminino</option>
                    </Select>
                  </Col>
                  <Col md={6}>
                    <Input
                      required
                      name="email"
                      error={formDataError.email}
                      value={formData.email}
                      label="E-mail"
                      type="email"
                    />
                  </Col>
                  <Col md={6} lg={3}>
                    <Input
                      name="telefone"
                      error={formDataError.telefone}
                      value={formData.telefone}
                      label="Telefone residencial"
                      type="text"
                      autoComplete='off'
                      max={15}
                      mask="phoneNumber"
                    />
                  </Col>
                  <Col md={6} lg={3}>
                    <Input
                      required
                      name="telefoneCelular"
                      error={formDataError.telefoneCelular}
                      value={formData.telefoneCelular}
                      label="Telefone celular"
                      type="text"
                      autoComplete='off'
                      max={15}
                      mask="phoneNumber"
                    />
                  </Col>
                  <Col md={6} lg={3}>
                    <Input
                      type='number'
                      name="rendaMensal"
                      error={formDataError.rendaMensal}
                      value={formData.rendaMensal}
                      label="Renda mensal"
                    />
                  </Col>
                  <Col md={6} lg={3}>
                    <Select
                      required
                      name="estadoCivil"
                      error={formDataError.estadoCivil}
                      value={formData.estadoCivil}
                      label="Estado Civil"
                    >
                      <option value="0">Selecione</option>
                      {utils?.estadoCivil?.map((ec: any) => <option key={'ec-' + ec.codEstadoCivil} value={ec.codEstadoCivil}>{ec.descricao}</option>)}
                    </Select>
                  </Col>
                  <Col md={5}>
                    <Input
                      name="nomeConjuge"
                      error={formDataError.nomeConjuge}
                      value={formData.nomeConjuge}
                      label="Nome do cônjuge ou companheiro(a)"
                    />
                  </Col>
                  <Col md={3} lg={2}>
                    <Input
                      required
                      name="cep"
                      min="8"
                      error={formDataError.cep}
                      defaultValue={formData.cep}
                      label="CEP"
                    />
                  </Col>
                  <Col md={5} lg={2}>
                    <Select
                      required
                      name="estado"
                      error={formDataError.estado}
                      value={formData.estado}
                      label="Estado"
                    >
                      <option value="0">Selecione</option>
                      {utils?.estado?.map((estado: any) => <option key={'estado-' + estado.idestado} value={estado.sigla}>{estado.sigla}</option>)}
                    </Select>
                  </Col>
                  <Col md={6} lg={3}>
                    <Select
                      required
                      name="cidade"
                      error={formDataError.cidade}
                      value={formData.cidade}
                      label="Cidade"
                    >
                      <option value="0">Selecione</option>
                      {/**
                         * Filtra cidades a apartir do estado selecionado
                         */
                        utils?.cidade
                        ?.filter((cidade: any) => cidade.idestado == utils.estado.filter((estado: any) => estado.sigla == formData.estado)[0]?.idestado)
                        .map((cidade: any) => <option key={'cidade-' + cidade.idcidade} value={cidade.cidade.toUpperCase()}>{cidade.cidade.toUpperCase()}</option>)}
                    </Select>
                  </Col>
                  <Col md={6}>
                    <Input
                      required
                      name="endereco"
                      error={formDataError.endereco}
                      value={formData.endereco}
                      label="Endereço"
                    />
                  </Col>
                  <Col md={6} lg={3}>
                    <Input
                      required
                      name="bairro"
                      error={formDataError.bairro}
                      value={formData.bairro}
                      label="Bairro"
                    />
                  </Col>
                </Row>
              </Card>
              {/* Inscrição no instituidor */}
              <Card>
                <Row className="gap-4 d-flex">
                  <Col sm={12}>
                    <h3 className="title-form-group">
                      Quem indicou? ( opcional )
                    </h3>
                  </Col>
                  {msgErroIndicou && <Col sm={12}>
                    <h3 className="title-form-group text-danger">
                      CPF não encontrado. Insira uma indicação válida, ou deixe estes campos sem preenchimento.
                    </h3>
                  </Col>}
                  <Col sm={12} md={6}>
                    <Input
                      name="cpfIndicou"
                      error={formDataError.cpfIndicou}
                      defaultValue={formData.cpfIndicou}
                      label="CPF"
                      mask="cpf"
                    />
                  </Col>
                  <Col sm={12} md={6}>
                    <Input
                      name="nomeIndicou"
                      label="Nome"
                      error={formDataError.nomeIndicou}
                      value={formData.nomeIndicou}
                    />
                  </Col>
                </Row>
              </Card>
              {/* Inscrição no instituidor */}
              <Card>
                <Row className="gap-4 d-flex">
                  <Col sm={12}>
                    <h3 className="title-form-group">
                      Inscrição no instituidor (AFUSI - Associação dos Funcionários da SIAS)
                    </h3>
                  </Col>
                  <Col md={6}>
                    <CheckboxRadio
                      required
                      name="inscricaoInstituidor"
                      error={formDataError.inscricaoInstituidor}
                      defaultChecked={formData.inscricaoInstituidor === "S"}
                      label="Já sou associado à AFUSI."
                      value="S"
                    >
                    </CheckboxRadio>
                  </Col>
                  <Col md={6}>
                    <CheckboxRadio
                      name="inscricaoInstituidor"
                      error={formDataError.inscricaoInstituidor}
                      defaultChecked={formData.inscricaoInstituidor === "N"}
                      label="Solicito minha inscrição da à AFUSI, nos termos de seu Estatuto Social, os quais declaro expressa ciência e concordância. "
                      value="N"
                    >
                    </CheckboxRadio>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <p><strong>*Observação</strong>: Nos termos do art. 31, inciso II, da Lei Complementar nº 109/2001, os planos de benefícios instituídos somente serão acessíveis aos associados do Instituidor, razão pela qual a adesão à AFUSI é imprescindível para a inscrição no Plano PrevSIAS.</p>
                  </Col>
                </Row>
              </Card>

              {/* Inscrção no PrevSIAS */}
              <Card>
                <Row>
                  <Col sm={12}>
                    <h3 className="title-form-group">
                      Inscrição no PrevSIAS
                    </h3>
                    <ul className="subtitle-form-group list-unstyled">
                      Poderão se inscrever no PrevSIAS, desde que associados ao Instituidor (AFUSI):
                      <li>I. Servidores, ex-servidores e aposentados, do quadro permanente ou temporário, do Instituto Brasileiro de Geografia e Estatística – IBGE;</li>
                      <li>II. Empregados, ex-empregados e aposentados da Sociedade Ibgeana de Assistência do e Seguridade – SIAS;	</li>
                      <li>III. Irmãos, ascendentes, descendentes e cônjuges das pessoas mencionadas nos itens I e II; e</li>
                      <li>IV. Cônjuges dos irmãos, dos ascendentes e dos descendentes das pessoas mencionadas nos itens I e II.	</li>
                    </ul>
                  </Col>
                  {inscritosArr?.map((inscr: iInscrito) =>
                    inscr.codigo !== "7" ?
                      <Col sm={4} key={'inscr-' + inscr.codigo}>
                        <label>
                          <CheckboxRadio
                            required
                            name="tipoInscrito"
                            value={inscr.codigo}
                            error={formDataError.tipoInscrito}
                            defaultChecked={formData.tipoInscrito === inscr.codigo}
                            label={inscr.tipo}
                          />
                          {(formData.tipoInscrito === "7" && inscr.codigo === "7") ? (
                            <div className="d-flex flex-column">
                              <Input
                                name="referencia"
                                value={formData.referencia} />
                              <span>Indicar o nome com o qual tem relação de parentesco e anexar comprovante dessa relação</span>
                            </div>
                          ) : null}
                        </label>
                      </Col>
                      :
                      <Col md={6} key={'inscr-' + inscr.codigo}>
                        <label className="d-flex">
                          <div className="mr-4">
                            <CheckboxRadio
                              required
                              name="tipoInscrito"
                              error={formDataError.tipoInscrito}
                              value="7"
                              defaultChecked={formData.tipoInscrito === "7"}
                              label="Outros" />
                          </div>
                          {formData.tipoInscrito === "7" ? (
                            <div className="d-flex flex-column">
                              <Input
                                name="referencia"
                                error={formDataError.referencia}
                                value={formData.referencia} />
                              <span>Indicar o nome com o qual tem relação de parentesco e anexar comprovante dessa relação</span>
                            </div>
                          ) : null}
                        </label>
                      </Col>)}

                </Row>
              </Card>

              {/* Pessoa politicamente exposta */}
              <Card>
                <Row>
                  <Col sm={12}>
                    <h3 className="title-form-group">
                      Pessoa politicamente exposta (Instrução Previc nº 34, de 28 de outubro de 2020)
                    </h3>
                  </Col>
                  <Col md={6}>
                    <p>
                      Exerce ou exerceu nos últimos cinco anos algum cargo, emprego ou função pública relevante, assim como funções relevantes em organização internacional, previstas na legislação vigente?
                    </p>
                    <Checkbox
                      name="politicamenteExposta"
                      defaultChecked={formData.politicamenteExposta}
                      onText="Sim"
                      offText="Não"
                    />
                  </Col>
                  <Col md={6}>
                    <p>
                      Possui relacionamento com pessoa que exerce ou exerceu nos últimos cinco anos algum cargo, emprego ou função pública relevante, bem como funções relevantes em organização internacional, previstas na legislação vigente?
                    </p>
                    <Checkbox
                      name="possuiRelacionamento"
                      defaultChecked={formData.possuiRelacionamento}
                      onText="Sim"
                      offText="Não"
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col className='d-flex justify-content-between'>
                    <input id='aceite' type='checkbox' required className='mt-1 mr-4' />
                    <label htmlFor='aceite'>
                      Declaro estar ciente que, na hipótese de adquirir a condição de Pessoa Politicamente Exposta após adesão ao PrevSIAS, deverei comunicar o fato imediatamente à SIAS, em observância à Instrução Previc nº 34/2020.
                    </label>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    {/* <a href="https://www.sias.org.br/home/wp-content/uploads/2018/06/Declara%c3%a7%c3%a3o-de-Pessoa-Politicamente-Exposta-SIAS-1.pdf" target="blank"><strong>Nota</strong>: Em caso afirmativo à pergunta acima, preencher o formulário "Declaração de Pessoa Politicamente Exposta - PPE".</a> */}
                    <a href="#!" onClick={() => setModalPPE(true)} ><strong>Nota</strong>: Em caso afirmativo à pergunta acima, preencher o formulário "Declaração de Pessoa Politicamente Exposta - PPE".</a>
                  </Col>




                  <WrapperModal>
                    <Modal show={modalPPE} onHide={setModalPPE} centered size="lg">
                      <Modal.Header closeButton>
                        <Modal.Title>
                          <Title>Declaração de Pessoa Politicamente Exposta</Title>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <p>O objetivo desta Declaração é atender à Instrução MPS/SPC nº 26/2008, de 1º/09/2008, a qual estabelece
                          os procedimentos que as Entidades de Previdência Complementar deverão adotar para o controle e
                          acompanhamento dos negócios e movimentações financeiras das “Pessoas Politicamente Expostas”, cuja
                          definição encontra-se abaixo.</p>

                        <p><b>1. Definição de Pessoa Politicamente Exposta, conforme Instrução MPS/SPC nº 26, de 1º/09/2008.</b><br />
                          Art. 3º Para efeito do disposto no inciso III do art. 2º, consideram-se pessoas politicamente expostas brasileiras:<br />
                          I - os detentores de mandatos eletivos dos Poderes Executivo e Legislativo da União;<br />
                          II - os ocupantes de cargo no Poder Executivo da União:<br />
                          a) de ministro de Estado ou equiparado;<br />
                          b) de natureza especial ou equivalente;<br />
                          c) de presidente, vice-presidente e diretor, ou equivalentes, de autarquias, fundações públicas,
                          empresas públicas ou sociedades de economia mista; e<br />
                          d) do Grupo Direção e Assessoramento Superiores - DAS, nível 6, e equivalentes;<br />
                          III - os membros do Conselho Nacional de Justiça, do Supremo Tribunal Federal e dos Tribunais
                          Superiores;<br />
                          IV - os membros do Conselho Nacional do Ministério Público, o Procurador-Geral da República, o
                          Vice- Procurador-Geral da República, o Procurador-Geral do Trabalho, o Procurador-Geral da
                          Justiça Militar, os Subprocuradores-Gerais da República e os Procuradores-Gerais de Justiça dos
                          Estados e do Distrito Federal;<br />
                          V - os membros do Tribunal de Contas da União e o Procurador-Geral do Ministério Público junto
                          ao Tribunal de Contas da União;<br />
                          VI - os governadores de Estado e do Distrito Federal, os presidentes de Tribunal de Justiça, de
                          Assembléia Legislativa ou da Câmara Distrital, e os presidentes de Tribunal ou Conselho de Contas
                          de Estado, de Municípios e do Distrito Federal; e<br />
                          VII - os prefeitos e os presidentes de Câmara Municipal das capitais de Estado.</p>


                        <p>2. Conforme definição, assinale abaixo se nos últimos cinco anos, retroativos a 1º/09/2008 e até esta
                          data, você ou algum de seus familiares na linha direta até 1º grau, inclusive seus representantes, é
                          ou já foi pessoa politicamente expostas no país ou no exterior.</p>

                        <Checkbox
                          labelText='2.1. Sou Pessoa Politicamente Exposta.'
                          label={true}
                          name="politicamenteExposta"
                          defaultChecked={formData.politicamenteExposta}
                          onText="Sim"
                          offText="Não"
                        />

                        <Checkbox
                          labelText='2.2. Possuo parentes de primeiro grau (pais e filhos), cônjuge, companheiro(a), enteado(a), inclusive representantes (pessoas que tenham minha procuração), Politicamente Expostos.'
                          label={true}
                          name="possuiRelacionamento"
                          defaultChecked={formData.possuiRelacionamento}
                          onText="Sim"
                          offText="Não"
                        />

                        {formData.possuiRelacionamento && <>
                        <hr />
                        <Title>Dados do parente PPE</Title>
                        <Input
                          required
                          name="PPE.nome"
                          error={formDataError.PPE.nome}
                          value={formData.PPE.nome}
                          label="Nome"
                        />
                        <Select
                          name="PPE.grauparente"
                          label="Grau de Parentesco"
                          error={formDataError.PPE.grauparente}
                          value={formData.PPE.grauparente}
                        >
                        <option value="0">Selecione</option>
                        {utils.parentescos.map((pt: any) => <option key={'pt-'+pt.codParentesco} value={pt.descricao}>{pt.descricao}</option>)}
                        </Select>
                        <Input
                          required
                          name="PPE.perexercicio"
                          error={formDataError.PPE.perexercicio}
                          value={formData.PPE.perexercicio}
                          label="Período de exercício ou mandato"
                        />
                        <Input
                          required
                          name="PPE.cargO_FUNCAO"
                          error={formDataError.PPE.cargO_FUNCAO}
                          value={formData.PPE.cargO_FUNCAO}
                          label="Cargo / Função"
                        />
                        <Input
                          required
                          name="PPE.Identidade"
                          error={formDataError.PPE.Identidade}
                          value={formData.PPE.Identidade}
                          label="Identidade"
                        />
                        <Input
                          required
                          name="PPE.orgaoemiS_DOC"
                          error={formDataError.PPE.orgaoemiS_DOC}
                          value={formData.PPE.orgaoemiS_DOC}
                          label="Órgão emissor"
                        />
                        <Input
                          required
                          name="PPE.dtemiS_DOC"
                          type='date'
                          error={formDataError.PPE.dtemiS_DOC}
                          value={formData.PPE.dtemiS_DOC}
                          label="Data emissão"
                        />
                        <Input
                          required
                          name="PPE.dtnasc"
                          type='date'
                          error={formDataError.PPE.dtnasc}
                          value={formData.PPE.dtnasc}
                          label="Nascimento"
                        />
                        <Input
                          required
                          name="PPE.cpfppe"
                          error={formDataError.PPE.cpfppe}
                          value={formData.PPE.cpfppe}
                          label="CPF"
                          mask="cpf"
                        />
                        </>}
                        <hr />
                        <p>Declaro, para os fins de que trata a Instrução MPS/SPC nº 26/2008, assumir inteira responsabilidade pela
veracidade e exatidão das informações aqui prestadas. Declaro, ainda, ter ciência de que deverei manter
atualizadas as informações ora apresentadas.</p>

                      </Modal.Body>
                      <Modal.Footer>
                        <WrapperButtons>
                          <Button
                            className="btn btn-success"
                            onClick={() => setModalPPE(false)}
                          >Confirmar</Button>
                        </WrapperButtons>
                      </Modal.Footer>
                    </Modal>
                  </WrapperModal>



                </Row>
              </Card>

              {/* Regime de tributação */}
              <Card>
                <Row>
                  <Col sm={12}>
                    <h3 className="title-form-group">
                      Regime de tributação
                    </h3>
                    <p className="subtitle-form-group">
                      A Lei n º 11.053, de 29 de dezembro de 2004, determina que, no momento da inscrição em um plano de previdência complementar estruturado na modalidade de contribuição definida, o requerente deverá optar, em caráter irrevogável e irretratável, pelo regime de tributação que incidirá sobre seu plano de previdência complementar, conforme abaixo:
                    </p>
                  </Col>
                  <Col md={6}>
                    <CheckboxRadio
                      required
                      error={formDataError.regimeTributacao}
                      name="regimeTributacao"
                      defaultChecked={formData.regimeTributacao === "PROGRESSIVO" ? true : false}
                      value="PROGRESSIVO"
                      label="Regime Progressivo incidência de Imposto de Renda na fonte com alíquota de 15%, como antecipação do imposto devido na declaração de ajuste anual da pessoa física, de acordo com o artigo 3º da Lei 11.053, de 29/12/2004. No caso de recebimento de benefício mensal, incidirá imposto de renda de acordo com as alíquotas previstas na Lei nº 11.482, de 31 de maio de 2007."
                    >
                    </CheckboxRadio>
                  </Col>
                  <Col md={6}>
                    <CheckboxRadio
                      required
                      error={formDataError.regimeTributacao}
                      name="regimeTributacao"
                      defaultChecked={formData.regimeTributacao === "REGRESSIVO" ? true : false}
                      value="REGRESSIVO"
                      label="Regime Regressivo: nas hipóteses de recebimento de resgate ou de benefício mensal, haverá incidência de imposto de renda, de caráter definitivo, ou seja, não poderá ser compensado na declaração de ajuste anual de pessoa física, de acordo com as alíquotas regressivas previstas no art. 1º da Lei nº 11.053, de 29 de dezembro de 2004."
                    >
                    </CheckboxRadio>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <a href="https://www.sias.org.br/home/wp-content/uploads/2019/06/Tributa%c3%a7%c3%a3o-de-Plano-de-Benef%c3%adcio.pdf" target="blank"><strong>Nota</strong>: Antes de preencher acima, leia o ofício "Entendendo os Regimes de Tributação".</a>
                  </Col>
                </Row>
              </Card>



              {formData.teM_PREVSIAS === "S" 
              ?
              <Col sm={12} className="text-center text-danger pt-4">
                <h2>
                  <b>CPF já cadastrado no Plano PrevSIAS. Adesão não permitida!</b>
                </h2>
              </Col>
              :
              <WrapperButtons className="wrapper-buttons">
                <Link to="/" className="btn">
                  Voltar
                </Link>
                <Button type='submit' className="btn btn-primary">
                  Próximo
                </Button>
              </WrapperButtons>}

            </Form>
          </Col>
        </Layout>
      </ComplementaryDataForm>



    </>
  );
};

export default DadosComplementares;
