import styled from 'styled-components';
import { transparentize } from 'polished';

export const ComplementaryDataForm = styled.section`
  h1 {
    text-align: center;
  }

  form {
    .card {
      .subtitle-form-group {
        color: ${transparentize(0.2, '#434A54')};
        display: block;
        line-height: 1.5;
        margin-bottom: 1.6rem;
        list-style: none;
      }
      div {
        div {
          & + div {
            label {
              margin-top: 2rem;
            }
          }
        }
      }
    }
    label {
      width: 100%;
      span {
        display: block;
        margin-bottom: 0.8rem;
      }
      font-size: 1.4rem;
      line-height: 1.5;
      & > div {
        & > div {
          & > div:not(:last-child) {
            height: 5rem;
            border-radius: var(--border-radius);
            align-items: center;
            background: var(--white);
            color: var(--color-text);
            transition: border 200ms, box-shadow 200ms;
            cursor: pointer;
          }
        }
      }
    }
    input:type[radio]{
      display: none;
    }
  }
`;

export const WrapperButtons = styled.div``;
