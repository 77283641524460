import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";

import { Simulation } from "../../../types/Simulation/simulation";

import { WrapperCard } from "./styles";
import { formatDate } from "../../../utils/formatDate";

// import { formatDate } from "../../../utils/formatDate";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  data: Simulation;
}

export const CardResult: React.FC<Props> = ({ data }) => {
  const onClick = () => {
    console.log(data)
    let dadosComplementares = { "politicamenteExposta":false,"possuiRelacionamento":false, "PPE":{}, dataNascimento: data.dataNascimento};
    sessionStorage.setItem("@SimuladorSias.simulacao", JSON.stringify(data));
    sessionStorage.setItem("@SimuladorSias.dadosComplementares", JSON.stringify(dadosComplementares));
    window.location.href = '/dados-complementares';
  };

  useEffect(() => {
    console.log(data)
  },[data]);

  return (
    <WrapperCard>
      <Card>
        <Card.Body>
          <Card.Title>
          {data.valorBeneficio == 0 ?
          "Valor benefício pagamento único de" :
          "Sua renda mensal (provável) será de"
          }
          </Card.Title>
          <Card.Subtitle>
            {(data.valorBeneficio == 0 ? data.valorAVista : data.valorBeneficio).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'})}
            
            {/* <span>
              Durante {data.prazoRecebRendaMeses.toLocaleString("pt-BR")}{" "}
              {data.prazoRecebRendaMeses === 1 ? "mês" : "meses"}.
            </span> */}
          </Card.Subtitle>
          <Card.Text>
            Investindo{" "}
            <strong> R${data.valorContribuicao.toLocaleString("pt-BR")}</strong>
            /mês por{" "}
            <strong>{data.descricaoTempoContribuicao.toLocaleString()}</strong>,
            com aporte inicial de R$
            {data.valorAporteInicial.toLocaleString("pt-BR")}. Vai acumular{" "}
            <strong>R$ {data.saldoBruto.toLocaleString("pt-BR")}</strong>
          </Card.Text>
          <Link className="btn" to="#!" onClick={onClick}>
            Quero esse
          </Link>
        </Card.Body>
      </Card>
      <Card className="more-details">
        <Card.Body>
            <Card.Title>Mais detalhes</Card.Title>Recebimento a partir do
            dia <strong> {formatDate(data.dataPrimeiroRecebimento)}</strong>,
            descontando o imposto {data?.ir?.tipo} no valor de{" "}
            <strong> R$ {data?.ir?.valor.toLocaleString("pt-BR")}</strong>{" "}
            aplicado a uma alíquota de{" "}
            <strong>{data?.ir?.aliquota.toLocaleString("pt-BR")}% de IR</strong>.
        </Card.Body>
      </Card>
    </WrapperCard>
  );
};

export default CardResult;
