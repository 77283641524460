import styled from 'styled-components';

export const Container = styled.button`
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.6rem 1.4rem;
  border: 0;
  outline: 0;
  font-size: 1.6rem;
  color: var(--white);
  font-weight: bold;
  transition: background-color 200ms, box-shadow 200ms;
  background-color: var(--color-dark);

  &:hover {
    background-color: var(--color-dark-hover);
    box-shadow: 0 0 0 0.4rem rgba(67, 74, 84, 0.3);

    &:focus {
      border: 0;
      background-color: var(--color-dark-hover);
      box-shadow: 0 0 0 0.6rem rgba(67, 74, 84, 0.3);
    }
  }

  &.primary {
    background-color: var(--primary);
    &:hover {
      background-color: var(--primary-hover);
      box-shadow: 0 0 0 0.4rem rgba(255, 159, 0, 0.3);
    }
    &:focus {
      background-color: var(--primary-hover);
      box-shadow: 0 0 0 0.6rem rgba(255, 159, 0, 0.3);
    }
  }
`;
