import React from 'react';

import { Pie } from 'react-chartjs-2';

import { Wrapper } from './styles';

interface Porcentagem {
  porcentagemContribuicao: number;
  porcentagemRendimento: number;
}

const ChartRentability: React.FC<Porcentagem> = ({
  porcentagemContribuicao,
  porcentagemRendimento,
}) => {
  const contribuicao = porcentagemContribuicao;
  const rendimento = porcentagemRendimento;

  return (
    <Wrapper>
      <Pie
        data={{
          labels: ['Contribuição', 'Rentabilidade'],
          datasets: [
            {
              label: '# of Votes',
              data: [contribuicao, rendimento],
              backgroundColor: [
                'hsl(37, 100%, 45%)',
                'hsl(37, 100%, 64%)',
              ],
              borderWidth: 0,
            },
          ],
        }}
        height={176}
        width={176}
        options={{
          maintainAspectRatio: false,
          legend: {
            position: 'right',
          },
        }}
      />
    </Wrapper>
  );
};

export default ChartRentability;
