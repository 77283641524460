import styled from 'styled-components';

import MenssageErro from '../MessageError';

export const Container = styled.div`
  height: 100%;
  display: flex;
  min-height: 8rem;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: .5rem;
  padding: 2rem;
  background: transparent;
  border: 1px solid #CCC;
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: background-color 200ms, border-color 200ms;

  &:hover{
    border-color: var(--primary);
  }
    
  label{
    display: block;
    pointer-events: none;
    padding-left: 2.8rem;
    transition: color 200ms;
  }
  
  input{
    -webkit-appearance: none;

    &:checked {
      background-color: rgba(255, 159, 0, 0.2);
      border-color: var(--primary);
    }
    &:checked ~ label {
      color: var(--primary);
      font-weight: bold;
    }
    &:before {
      content: '';
      position: absolute;
      height: 2.4rem;
      width: 2.4rem;
      border-radius: 1.2rem;
      top: 50%;
      left: 3rem;
      transform: translateY(-50%);
      background: #FFF;
      border: 1px solid #999;
      transition: border 200ms;
    }
    &:checked:before,
    &:checked:before {
      border: 0.6rem solid var(--primary-hover);
    }
  }

`;

export const Error = styled(MenssageErro)`
  margin-top: 7px;
  margin-right: 7px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    display: flex;
    margin-top: -7px;
    margin-right: 7px;
    color: var(--error);
  }

  /* span {
    color: var(--error);
    position: absolute;
    width: 100%;
    top: calc(100% + 0.6rem);
    left: 0;
  } */
`;
