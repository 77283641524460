import styled from 'styled-components';
import { transparentize } from 'polished';

import iconAlert from '../../assets/iconInfo.svg';

export const BankDataForm = styled.div`
  label {
    width: 100%;
    cursor: pointer;
  }

  form {
    .card {
      .row {
        div {
          margin-bottom: 0.6rem;
        }
      }
    }
  }

  .alert {
    margin-top: 3rem;
    background: ${transparentize(0.6, '#ffc10a')};
    border-radius: var(--border-radius);
  }
`;

export const TitleFormGroup = styled.h3``;

export const WrapperAlert = styled.div`
  padding: 4rem 2rem;
  display: flex;
  align-items: center;
`;
export const IconAlert = styled.div`
  height: 8rem;
  width: 8rem;
  background: url(${iconAlert}) no-repeat center/cover;
  margin-right: 3rem;
`;

export const ContentAlert = styled.div`
  color: var(--dark);
  font-size: 1.4rem;
  .alert-heading {
    font-size: 1.6rem;
    font-weight: bold;
  }
`;
export const WrapperLinks = styled.div`
  display: flex;
  margin-top: 3rem;
  a {
    font-size: 1.6rem;
    font-weight: bold;
    color: var(--dark);
    text-decoration: none;
    opacity: 0.6;
    transition: opacity 200ms ease-in-out;

    &:not(:first-child) {
      margin-left: 2rem;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export const WrapperButtons = styled.div``;
