import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

import { FaExclamationCircle } from 'react-icons/fa';
import { Container, Error } from './styles';

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  id?: string;
  label?: string;
  icon?: React.ComponentType<IconBaseProps>;
  error?: string;
}

const ChackboxRadio = ({ children, name, id, label, error, ...rest }:Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <Container style={rest.disabled ? {opacity:0.3} : {}}>
      <input type="radio" name={name} id={name} value={defaultValue} ref={inputRef} {...rest} />
      <label htmlFor={name}>{label}</label>
      {error && (
        <Error title={error}>
          <FaExclamationCircle size={16} />
        </Error>
      )}
    </Container>
  );
};

export default ChackboxRadio;
