import styled from 'styled-components';

export const Title = styled.h1`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;
export const UserData = styled.div`
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-end;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: center;
  margin-bottom: 4rem;
  align-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;

  div {
    margin-right: 3rem;
  }

  div > img {
    width: 70px;
    height: 70px;
  }
  
  h3 {
    font-size: 1.6rem;
  }

  p {
    position: flex;
    background: var(--white);
    padding: 1rem 1rem 4.5rem 2rem;
    max-width: 42rem;
    border-radius: var(--border-radius);
    font-size: 1.6rem;

    span {
      display: block;
      max-width: strong {
        color: var(--primary);
      }
    }
  }
`;

export const WrapperTable = styled.div`
  .card {
    padding-bottom: 4rem;
    margin-bottom: 5rem;

    table {
      border: 0;
      tr {
        th {
          border: 0;
          h3 {
            font-size: 1.6rem;
            font-weight: bold;
          }
        }
        td {
          border: 0;
          & + td {
            text-align: right;
          }
        }
      }
    }
  }
  .btn {
    bottom: -10%;
  }
`;

export const SelectedPlan = styled.div``;
export const MoreDetails = styled.div``;
export const TitleCard = styled.h3`
  font-size: 1.6rem;
  font-weight: bold;
  margin-bottom: 1.6rem;
`;
export const Balance = styled.p`
  font: 3.7rem var(--font-primary);
  margin-bottom: 0.2rem;
`;
export const Period = styled.span`
  font-size: 1.4rem;
  font-weight: 500;
`;

export const Result = styled.span`
  font-size: 1.2rem;
  line-height: 1.5;
`;

export const WrapperButtons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    width: calc(100% - 4rem);

    & + button {
      margin-left: 3rem;
    }
  }

  @media (max-width: 42rem) {
    flex-direction: column;

    button {
      & + button {
        margin-left: 0;
        margin-top: 2rem;
      }
    }
  }
`;

export const WrapperModal = styled.div`
  h1 {
    margin-bottom: 0 !important;
  }
`;
