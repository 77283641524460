import React from 'react';

import { Wrapper } from './styles';
import logoImg from '../../assets/logosias.jpg';

const Header: React.FC = () => {
  return (
    <>
      <Wrapper>
        <a href="/">
          <img className="logoSias" src={logoImg} alt="Plano SIASPrev" />
        </a>
      </Wrapper>
    </>
  );
};

export default Header;
