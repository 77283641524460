export function formatDate(date: Date | string): string {
  if(!date || date === "")
  return "";

  if (typeof date === "string") {
    if(date.length < 11)
    date += "T00:00:00";
    date = new Date(date);
  }
  return date.toLocaleDateString("pt-BR");
}
