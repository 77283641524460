export function cpf(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1-$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');
  e.currentTarget.value = value;
  return value;
}
export function rg(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 7;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1-$2');
  value = value.replace(/^(\d{1,2})(\d{3})(\d{3})([\dX])$/,'$1.$2.$3-$4')
  e.currentTarget.value = value;
  return value;
}

export function phoneNumber(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 15;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{2})(\d)/g, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  e.currentTarget.value = value;
  return value;
}
export function currentAccountDigit(e: React.FormEvent<HTMLInputElement>) {
  let value = e.currentTarget.value;
  value = value.replace(/(\d)(\d{1})$/, '$1-$2');
  e.currentTarget.value = value;
  return value;
}
