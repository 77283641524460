import React, { InputHTMLAttributes, useEffect, useRef, useState } from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';

import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  label?: boolean;
  labelText?: string,
  onText?: string;
  offText?: string;
}

const Input: React.FC<InputProps> = ({ icon: Icon, name, label, labelText, onText, offText, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField, defaultValue } = useField(name);
  
  const [checked, setChecked] = useState(false);
  const checkedText = onText;
  const unCheckedText = offText;
  const handleChange = () => {
    setChecked(!checked); 
  }


  useEffect(() => {
    setChecked(rest.defaultChecked ? true : false);
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField, rest.defaultChecked]);

  return (
    <Container>
      <p>{labelText}</p>
      <input
        type="checkbox"
        name={name}
        defaultValue={defaultValue}
        onChange={handleChange}
        {...rest}
      />
      <p>{checked ? checkedText : unCheckedText}</p>
    </Container>
  );
};

export default Input;
