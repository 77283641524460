import React, { useEffect } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { Wrapper } from './styles';

const SimulatorNavigation: React.FC = () => {
  const Page = {
    dadosComplementares: sessionStorage.getItem('@SimuladorSias.dadosComplementares') ? true : false,
    dadosBancarios: sessionStorage.getItem('@SimuladorSias.dadosBancarios') ? true : false,
    beneficiarios: sessionStorage.getItem('@SimuladorSias.beneficiarios') ? true : false
  }

  useEffect(() => {
    if(!sessionStorage.getItem('@SimuladorSias.simulacao') || !sessionStorage.getItem('@SimuladorSias.utils'))
    window.location.href = '/';
  },[]);

  return (
    <>
      <Wrapper>
        <Breadcrumb>
          <li>
            <Link className={Page.dadosComplementares ? "" : "disable"} to="/dados-complementares">
              <span>1ª</span>
              <strong>Dados pessoais</strong>
            </Link>
          </li>
          <li>
            <Link className={Page.dadosBancarios ? "" : "disable"} to="/dados-bancarios">
              <span>2ª</span>
              <strong>Dados bancários</strong>
            </Link>
          </li>
          <li>
            <Link className={Page.beneficiarios ? "" : "disable"} to="/beneficiarios">
              <span>3ª</span>
              <strong>Beneficiários</strong>
            </Link>
          </li>
          <li>
            <Link className={(Page.dadosComplementares && Page.beneficiarios && Page.beneficiarios) ? "" : "disable"} to="/adesao">
              <span>4ª</span>
              <strong>Adesão</strong>
            </Link>
          </li>
        </Breadcrumb>
      </Wrapper>
    </>
  );
};

export default SimulatorNavigation;
