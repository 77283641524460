import React, { useState } from 'react';
import { Col, Card, Row, Alert } from 'react-bootstrap';
import { Form } from '@unform/web';
import { Link, useHistory } from 'react-router-dom';

import Layout from '../../components/Layout';
import SimulatorNavigation from '../../components/SimulatorNavigation';
import Header from '../../components/Header';
import Select from '../../components/Form/Select';
import Input from '../../components/Form/Input';
import Button from '../../components/Button';

import { ComplementaryDataForm, WrapperButtons } from './styles';
import { Beneficiario } from '../../types/Beneficiario/Beneficiario';
import { Dados } from '../../types/DadosComplementares/dadosComplementares';

const Beneficiarios: React.FC = () => {
  const history = useHistory();
  /**
   * Cria um hash aleatório
   * @param length numero de caracteres
   * @returns id
   */
  const makeid = (length: number = 5) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++)
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    return result;
  }
  // lista default de beneficiarios 
  // const defaultLista = '[{"grupo":1,"hash":"' + makeid() + '"},{"grupo":2,"hash":"' + makeid() + '"}]';

  // pega lista default ou então pega lista salva no session storage
  const [listaBeneficiarios, setListaBeneficiarios] = useState<Beneficiario[]>(JSON.parse(sessionStorage.getItem('@SimuladorSias.beneficiarios') || '[]') as Beneficiario[]);
  const [utils] = useState(JSON.parse(sessionStorage.getItem('@SimuladorSias.utils') || '{}'));
  const [dadosComplementares] = useState<Dados>(JSON.parse(sessionStorage.getItem('@SimuladorSias.dadosComplementares') || ''));
  const [MsgErroBenG1, setMsgErroBenG1] = useState(false);
  const [MsgErroBenG2, setMsgErroBenG2] = useState(false);

  const [MsgErroBenNomeCPFG1, setMsgErroBenNomeCPFG1] = useState(false);
  const [MsgErroBenNomeCPFG2, setMsgErroBenNomeCPFG2] = useState(false);

  /**
   * Adicioa novo input a lista de beneficiarios
   * @param e evento submissão do formulario
   */
  const handleSubmit = (e: any) => {
    let lista: any = listaBeneficiarios;
    setListaBeneficiarios([...lista, { grupo: e.grupo, hash: makeid() } as Beneficiario]);
  }

  /**
   * Deleta beneficiario da lista de beneficiarios
   * de acordo com o hash
   * @param e evento click deletar beneficiario
   */
  const handleDeleteBen = (e: any) => {
    let hash: string = e.target.dataset.hash;
    let lista: any = listaBeneficiarios;
    let index: number = lista.findIndex((x: { hash: string; }) => x.hash === hash); // find index of the element

    lista.splice(index, 1);
  }


  /**
   * Pega mudança de valor de um input e atualiza o objeto dentro do array de beneficiarios
   * de acordo com o hash do beneficiario
   * @param e evento mudança de estado do formulario
   */
  const handleChangeBeneficiario = (e: any) => {
    let hash: string = e.target.dataset.hash;
    let name: string = e.target.name;
    let value: string = e.target.value;
    let lista: any = listaBeneficiarios;
    let index: number = lista.findIndex((x: { hash: string; }) => x.hash === hash); // find index of the element

    lista[index][name] = name === 'proporcao' ? parseInt(value) : value;
    setListaBeneficiarios(lista);
  }

  /**
   * 
  *     Salva dados no session storage
  * 
  */
  const saveToSessionStorage = () => {
    let lista: any = listaBeneficiarios;
    lista = lista.filter((ben: any) => ben.proporcao !== null)

    /**
     * Caso tenha cadastrado baneficiario 
     */
    if (lista.filter((ben: any) => ben.grupo === 1).length > 0) {
      // faz a soma total de porcentagens
      let limitGrupo1 = lista.filter((ben: any) => ben.grupo === 1).reduce((a: any, b: any) => ({ proporcao: parseInt(a.proporcao) + parseInt(b.proporcao) }));
      // setta mensagem de erro caso a soma não seja igual a 100
      setMsgErroBenG1(limitGrupo1.proporcao !== 100);
      // caso a soma não tenha sido maior que 100 não prossegue 
      if (limitGrupo1.proporcao !== 100) return;
    }

    /**
     * Caso tenha cadastrado baneficiario Indicado
     */
    if (lista.filter((ben: any) => ben.grupo === 2).length > 0) {
      // faz a soma total de porcentagens
      let limitGrupo2 = lista.filter((ben: any) => ben.grupo === 2).reduce((a: any, b: any) => ({ proporcao: parseInt(a.proporcao) + parseInt(b.proporcao) }));
      // setta mensagem de erro caso a soma não seja igual a 100
      setMsgErroBenG2(limitGrupo2.proporcao !== 100);
      // caso a soma não tenha sido maior que 100 não prossegue 
      if (limitGrupo2.proporcao !== 100) return;
    }

    /**
     * Não permite gravar caso não informe Nome e CPF do beneficiario
     */
    lista.forEach((ben: any) => {
      if ((!ben.nomeDependente || ben?.nomeDependente?.length < 3) || (!ben.cpf || ben?.cpf?.length < 3)) {
        setMsgErroBenNomeCPFG1(ben.grupo === 1);
        setMsgErroBenNomeCPFG2(ben.grupo === 2);
        return;
      }else{
        ben.grupo === 1 ? setMsgErroBenNomeCPFG1(false) : setMsgErroBenNomeCPFG2(false);
      }
    })

    /**
     * Salva no session storage e vai para a pagina de adesao
     */
    sessionStorage.setItem('@SimuladorSias.beneficiarios', JSON.stringify(listaBeneficiarios));
    history.push('/adesao');
  }



  return (
    <>
      <Header />
      <SimulatorNavigation />
      <ComplementaryDataForm>
        <Layout>
          <Col sm={12}>
            <h1>
              Perfeito, {dadosComplementares.nome.split(' ')[0]}, agora preencha os dados dos <span>beneficiários</span>.
            </h1>
          </Col>
          <Col sm={12}>
            <Form
              onSubmit={obj => handleSubmit({ ...obj, grupo: 1, hash: makeid() })}
            >
              {/* Relação de Beneficiários */}
              <Card>
                <Row>
                  <Col sm={12}>
                    <h3 className="title-form-group">Relação de Beneficiários (Art. 3º, alínea III do regulamento)</h3>
                    <p className="subtitle-form-group">
                      Consideram-se Beneficiários os dependentes que o participante indicar e que, nessa qualidade, tenham sido reconhecidos pela Previdência Social.
                    </p>
                  </Col>

                  {(MsgErroBenNomeCPFG1) &&
                    <Col sm={12}>
                      {MsgErroBenNomeCPFG1 && <Alert variant='danger'>Preencha corretamente os Campos "Nome" e "CPF" de cada Beneficiário!</Alert>}
                    </Col>}

                  <Col sm={12}>
                    <hr />
                  </Col>
                </Row>

                {listaBeneficiarios
                  .filter(ben => ben.grupo === 1)
                  .map((ben, index) =>
                    <Row
                      key={'ben-1-' + ben.hash + '-' + Math.floor(Math.random() * 100)}
                    >

                      {/* {listaBeneficiarios
                        .filter(ben => ben.grupo === 1)
                        .length !== 1 && */}
                      <Col sm={12} className="d-flex justify-content-end">
                        <Link to="#!" data-hash={ben.hash} onClick={handleDeleteBen}>EXCLUIR</Link>
                      </Col>
                      {/* } */}

                      <Col sm={6}>
                        <Input
                          name="nomeDependente"
                          label="Nome do beneficiário"
                          data-prevsocial="S"
                          data-indicados="N"
                          data-hash={ben.hash}
                          defaultValue={ben?.nomeDependente}
                          onChange={handleChangeBeneficiario}
                        />
                      </Col>
                      <Col md={6} lg={3}>
                        <Input
                          name="dataNascimento"
                          type="date"
                          label="Data de nascimento"
                          data-hash={ben.hash}
                          defaultValue={ben?.dataNascimento}
                          onChange={handleChangeBeneficiario}
                        />
                      </Col>
                      <Col lg={3}>
                        <Select
                          name="genero"
                          label="Gênero"
                          data-hash={ben.hash}
                          defaultValue={ben?.genero}
                          onChange={handleChangeBeneficiario}
                        >
                          {!ben?.genero && <option value="0">Selecione</option>}
                          <option value="M">Masculino</option>
                          <option value="F">Feminino</option>
                        </Select>
                      </Col>
                      <Col sm={12} md={6} lg={3}>
                        <Input
                          name="cpf"
                          label="CPF"
                          mask="cpf"
                          data-hash={ben.hash}
                          defaultValue={ben?.cpf}
                          onChange={handleChangeBeneficiario}
                        />
                      </Col>
                      <Col lg={3}>
                        <Select
                          name="grauDependencia"
                          label="Grau de dependência"
                          data-hash={ben.hash}
                          value={ben.grauDependencia}
                          onChange={handleChangeBeneficiario}
                        >
                          <option value="0">Selecione</option>
                          {utils.parentescos //1, 2, 3 5 
                            .filter((x: any) => (x.codParentesco == 1 || x.codParentesco == 2 || x.codParentesco == 3 || x.codParentesco == 5))
                            .map((pt: any) => <option key={'pt-' + pt.codParentesco} value={pt.codParentesco}>{pt.descricao}</option>)}
                        </Select>
                      </Col>
                      <Col sm={6}>
                        <Input
                          name="proporcao"
                          type='number'
                          max={100}
                          label='Porcentagem'
                          data-hash={ben.hash}
                          defaultValue={ben?.proporcao}
                          onChange={handleChangeBeneficiario}
                        />
                      </Col>
                      <Col sm={12}>
                        <hr />
                      </Col>
                    </Row>)}
                {/* </Col> */}

                <Row>
                  <Col sm={12}>
                    <Button type='submit' className="btn btn-primary">
                      Adicionar novo beneficiário
                    </Button>
                  </Col>
                </Row>
              </Card>
              {/* <hr /> */}
            </Form>







            <Form
              onSubmit={obj => handleSubmit({ ...obj, grupo: 2, hash: makeid() })}
            >
              {/* Relação de Beneficiários Indicados */}
              <Card style={{ marginTop: 100, marginBottom: 100 }}>
                <Row>
                  <Col sm={12}>
                    <h3 className="title-form-group">Relação de Beneficiários Indicados para o recebimento do Saldo de conta (Art. 3º, alínea III do regulamento)</h3>
                    <p className="subtitle-form-group">
                      No caso de falecimento do Participante, não havendo Beneficiários, o Beneficiário indicado receberá, na formade pagamento único, o saldo da Conta do Participante determinado na data de cálculo, de acordo com a proporção indicada.
                    </p>
                  </Col>

                  {(MsgErroBenNomeCPFG2) &&
                    <Col sm={12}>
                      {MsgErroBenNomeCPFG2 && <Alert variant='danger'>Preencha corretamente os Campos "Nome" e "CPF" de cada Beneficiário!</Alert>}
                    </Col>}

                  <Col sm={12}>
                    <hr />
                  </Col>
                  {listaBeneficiarios
                    .filter(ben => ben.grupo === 2)
                    .map((ben, index) =>
                      <Row
                        key={'ben-2-' + index + '-' + Math.floor(Math.random() * 100)}
                      >

                        {/* {listaBeneficiarios
                          .filter(ben => ben.grupo === 1)
                          .length !== 1 && */}
                        <Col sm={12} className="d-flex justify-content-end">
                          <Link to="#!" data-hash={ben.hash} onClick={handleDeleteBen}>EXCLUIR</Link>
                        </Col>
                        {/* } */}

                        <Col sm={6}>
                          <Input
                            required
                            name="nomeDependente"
                            label="Nome do beneficiário"
                            data-prevsocial="S"
                            data-indicados="N"
                            data-hash={ben.hash}
                            defaultValue={ben?.nomeDependente}
                            onChange={handleChangeBeneficiario}
                          />
                        </Col>
                        <Col md={6} lg={3}>
                          <Input
                            required
                            name="dataNascimento"
                            type="date"
                            label="Data de nascimento"
                            data-hash={ben.hash}
                            defaultValue={ben?.dataNascimento}
                            onChange={handleChangeBeneficiario}
                          />
                        </Col>
                        <Col lg={3}>
                          <Select
                            required
                            name="genero"
                            label="Gênero"
                            data-hash={ben.hash}
                            defaultValue={ben?.genero}
                            onChange={handleChangeBeneficiario}
                          >
                            {!ben?.genero && <option value="0">Selecione</option>}
                            <option value="M">Masculino</option>
                            <option value="F">Feminino</option>
                          </Select>
                        </Col>
                        <Col sm={12} md={6} lg={3}>
                          <Input
                            required
                            name="cpf"
                            label="CPF"
                            mask="cpf"
                            data-hash={ben.hash}
                            defaultValue={ben?.cpf}
                            onChange={handleChangeBeneficiario}
                          />
                        </Col>
                        <Col lg={3}>
                          <Select
                            required
                            name="grauDependencia"
                            label="Vínculo"
                            data-hash={ben.hash}
                            value={ben?.grauDependencia}
                            onChange={handleChangeBeneficiario}
                          >
                            <option value="0">Selecione</option>
                            {utils.parentescos // 8, 12, 9 , 18, 14, 13
                              .filter((x: any) => (x.codParentesco == 17
                                || x.codParentesco == 7
                                || x.codParentesco == 8
                                || x.codParentesco == 12
                                || x.codParentesco == 9
                                || x.codParentesco == 18
                                || x.codParentesco == 14
                                || x.codParentesco == 13))
                              .map((pt: any) => <option key={'pti-' + pt.codParentesco} value={pt.codParentesco}>{pt.descricao}</option>)}
                          </Select>
                        </Col>
                        <Col sm={6}>
                          <Input
                            required
                            name="proporcao"
                            type='number'
                            max={100}
                            label='Porcentagem'
                            data-hash={ben.hash}
                            defaultValue={ben?.proporcao}
                            onChange={handleChangeBeneficiario}
                          />
                        </Col>
                        <Col sm={12}>
                          <hr />
                        </Col>
                      </Row>)}

                  <Col sm={12}>
                    <Button type='submit' className="btn btn-primary d-flex">
                      Adicionar novo beneficiário
                    </Button>
                  </Col>
                </Row>
              </Card>

              {(MsgErroBenG1 || MsgErroBenG2) &&
                <Row>
                  <Col sm={12}>
                    {MsgErroBenG1 && <Alert variant='danger'>A Soma total de porcentagem dos beneficiários tem que ser igual a 100% !</Alert>}
                    {MsgErroBenG2 && <Alert variant='danger'>A Soma total de porcentagem dos beneficiários <b>Indicados</b> tem que ser igual a 100% !</Alert>}
                  </Col>
                </Row>}

              <WrapperButtons className="wrapper-buttons">
                <Link to="/dados-bancarios" className="btn">
                  Voltar
                </Link>
                <Button
                  onClick={saveToSessionStorage}
                  className="btn btn-primary">
                  Próximo
                </Button>
              </WrapperButtons>
            </Form>
          </Col>
        </Layout>
      </ComplementaryDataForm>
    </>
  );
};

export default Beneficiarios;

