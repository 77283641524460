import styled from 'styled-components';

export const SimulationPlan = styled.div`
  background: var(--color-background-light);
  border-radius: var(--border-radius);
  padding: 2rem 3rem;
  margin-bottom: 6rem;
  position: sticky;
  top: 2rem;

  h1 {
    text-align: center;
  }

  form {
    font-size: 1.4rem;
    /* padding-bottom: 3rem; */
    position: relative;

    label {
      width: 100%;

      & + label {
        margin-top: 2.4rem;
      }
      div {
        margin-top: 0.8rem;
      }
    }

    button {
      position: absolute;
      bottom: -4.5rem;
      width: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
`;

export const SimulationResult = styled.div`
  background: var(--white);
  padding: 2rem;
  border-radius: var(--border-radius);

  h1 {
    max-width: 100%;
    text-align: left;
  }

  strong {
    display: inline-block !important;
    margin-bottom: 0 !important;
  }

  table {
    .sias-primary {
      color: var(--primary);
      font-weight: bold;
    }
  }

  .nav-item .nav-link {
    color: var(--color-text);
    opacity: 0.6;
    transition: opacity 200ms ease-in-out;
    padding: 2rem;

    &:hover {
      opacity: 1;
    }
  }
`;

export const InputGroup = styled.div`
  display: flex;

  label {
    display: block;
    flex: 1;
  }

  input {
    width: auto;

    & [type='range'] {
      display: flex;
      width: 100%;
    }
  }

  & + div {
    margin-top: 1.6rem;
  }
`;
