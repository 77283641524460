import React, {
  SelectHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { useField } from '@unform/core';
import { FaExclamationCircle } from 'react-icons/fa';

import { Container, Error } from './styles';

interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  label?:string;
  error?:string;
}

const Select: React.FC<Props> = ({ children, name, label, error, ...rest }) => {
  const selectRef = useRef<HTMLSelectElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isField, setIsField] = useState(false);
  const { fieldName, registerField, defaultValue } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

    setIsField(!!selectRef.current?.value);
  }, []);

  return (
    <Container isErrored={!!error} isField={isField} isFocused={isFocused}>
      <span>{label}</span>
      <select
        defaultValue={defaultValue}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        name={name}
        {...rest}
        ref={selectRef}
      >
        {children}
      </select>
      {error && (
        <Error title={error}>
          <FaExclamationCircle size={16} />
        </Error>
      )}
    </Container>
  );
};

export default Select;
