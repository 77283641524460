import styled from 'styled-components';

export const WrapperCard = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  & + div {
    padding-left: 2.8rem;
    @media (max-width: 1200px) {
      &:first-child {
        width: 100%;
      }
      padding-left: 0;
      margin-top: 1.6rem;
    }
  }

  &:first-child {
    .card {
      background-color: var(--secondary);
      color: var(--white);
      .card-subtitle {
        color: var(--primary);
        span {
          color: var(--white);
        }
      }

      .card-title {
        p {
          color: var(--primary);
        }
      }
    }

    .btn {
      background-color: var(--primary);
      color: var(--white);
      &:hover {
        background-color: var(--primary-hover);
        box-shadow: 0 0 0 0.4rem rgba(255, 159, 0, 0.3);
      }
      &:focus {
        background-color: var(--primary-hover);
        box-shadow: 0 0 0 0.6rem rgba(255, 159, 0, 0.3);
      }
    }

    .more-details {
      margin-top: 4rem;
      background: var(--white);
      color: var(--color-text);
    }
  }

  .card {
    flex: 1;
    .card-body {
      .card-title {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 0.8rem;
      }
      .card-subtitle {
        font: 3.7rem var(--font-primary);
        margin-bottom: 0;
        span {
          font-size: 1.4rem;
          font-weight: 500;
          display: block;
          margin-bottom: 1.6rem;
        }
      }
      .cart-text {
        font-size: 1.2rem;
        line-height: 1.5;
      }
    }
  }

  .more-details {
    margin-top: 4rem;

    .card-text > strong {
      display: block;
      margin-bottom: 1.6rem;
    }
  }
`;
